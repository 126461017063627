import React, { Component } from 'react'
import { Segment, Grid, Image, Form, Header, Label, Button, DropdownItemProps, Radio } from 'semantic-ui-react'
import LocationInput, { LocationInputValue } from './../../../../../common/LocationInput'
import StateDistrictRepository from '../../../../../../common/repository/StateDistrictRepository'
import AllocatedInvestigator from '../../../../../../models/AllocatedInvestigator'
import LabelContainer from '../../LabelContainer'
import URLResource from '../../../../../../models/URLResource'
import FileLabel from '../../../../../common/FileLabel'
import CaseRepository from '../../../../../../common/repository/CaseRepository'
import constants from '../../../../../../common/constants'
import Location from '../../../../../../models/Location'
import Result from '../../../../../../common/repository/Result'
import { toast } from '../../../../../common/Toast'
import InvestigatorAllowance from '../../../../../../models/InvestigatorAllowance'
import InvestigatorAllowanceRepository from '../../../../../../common/repository/InvestigatorAllowanceRepository'
import User from '../../../../../../models/User'
import validator from './../../../../../../common/validator'


interface Props {
    model: AllocatedInvestigator
    onSuccess?(model: AllocatedInvestigator): void
}

interface State {
    loading: boolean
    outcome: string
    casePortion: string
    location: LocationInputValue | null
    documentExpense: string
    documentExpenseFiles: File[]
    approvedExpense: string
    approvedExpenseFiles: File[]
    extraExpense: string
    extraExpenseFiles: File[]
    isOutcomeYes: boolean
    disableAllowanceInput: boolean
    allowance: string
    loadingInvestigationAllowance: boolean
    investigatorAllowance: InvestigatorAllowance | null

}


/**
 * Renders FinishInvestigation Form
 *
 * @param {AllocatedInvestigator} model - AllocatedInvestigator Model
 * @param {object} onSuccess - Callback functions which returns updated AllocatedInvestigator upon success
 */
class FinishInvestigationForm extends Component<Props, State> {
    
    private stateDistrictRepo = new StateDistrictRepository()
    private investigatorAllowanceRepo = new InvestigatorAllowanceRepository();
    private caseRepo = new CaseRepository()


    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            outcome: '',
            casePortion: '',
            location: null,
            documentExpense: '0',
            documentExpenseFiles: [],
            approvedExpense: '0',
            approvedExpenseFiles: [],
            extraExpense: '0',
            extraExpenseFiles: [],
            disableAllowanceInput: true,
            allowance: '',
            loadingInvestigationAllowance: false,
            investigatorAllowance: null,
            isOutcomeYes: false,
        }
    }
    componentDidMount() {
        this.getInvestigationAllowance()
        let model = this.props.model;
        if(model.getOutcome()) this.setState({outcome: model.getOutcome()!});
        if (model.getOutcome()) {
            this.setState({ 
                outcome: model.getOutcome()!, 
                isOutcomeYes: true 
            });
        }
        if(model.getCasePortion()) this.setState({casePortion: (model.getCasePortion()!).toString()});
        if(model.getInvestigationLocation()) this.setState({location: {state: model.getInvestigationLocation()!.getState(), district: model.getInvestigationLocation()!.getDistrict()}})
        if(model.getAllowance()>0) this.setState({allowance: (model.getAllowance()).toString()});
        if(model.getApprovedExpense()>0) this.setState({approvedExpense: (model.getApprovedExpense()).toString()});
        if(model.getExtraExpense()>0) this.setState({extraExpense: (model.getExtraExpense()).toString()});
        if(model.getDocumentExpense()>0) this.setState({documentExpense: (model.getDocumentExpense()).toString()});
    }


    getInvestigationAllowance = () => {
        this.setState({ loadingInvestigationAllowance: true }, async () => {
            const result = await this.investigatorAllowanceRepo.getInvestigatorAllowance()
            if (result instanceof Result.Success) {
                this.setState({ investigatorAllowance: result.data, loadingInvestigationAllowance: false })
            } else {
                const message = result.message || 'Could not load Investigation Allowance'
                toast.error(message)
                this.setState({ loadingInvestigationAllowance: false })
            }
        })
    }


    onFinishInvestigation = () => {
        this.setState({ loading: true }, async () => {
            const { model, onSuccess } = this.props

            //make request
            const result = await this.caseRepo.finishInvestigation({
          
                allocation_id: model.getId(),
                outcome: this.state.outcome,
                case_portion: Number(this.state.casePortion),
                investigation_location: this.state.location!,
                allowance: Number(this.state.allowance),
                document_expense: Number(this.state.documentExpense),
                document_expense_receipt: this.state.documentExpenseFiles,
                approved_expense: Number(this.state.approvedExpense),
                approved_expense_receipt: this.state.approvedExpenseFiles,
                extra_expense: Number(this.state.extraExpense),
                extra_expense_receipt: this.state.extraExpenseFiles
            })
            console.log(result,"lll")
            if (result instanceof Result.Success) {
                this.setState({ loading: false }, () => {
                    toast.success('Successfully finished investigation')
                    const allocatedInvestigator = result.data
                    if (onSuccess) onSuccess(allocatedInvestigator)
                })
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                })
            }
        })
    }


    render() {
        const { model } = this.props
        const investigator = model.getInvestigator();
        return (
          <>
            <Segment basic loading={this.state.loading}>
                <Grid columns={2}>
                    <Grid.Row>
                        
                        {/* Investigator Profile */}
                        <Grid.Column width={5}>
                            <Image src={investigator.getProfilePicURL()} />
    
                            <Header as='h3'>
                                {investigator.getName()}
                                <Header.Subheader>
                                    Allocated by - {model.getAllocatedBy().getName()} on {model.getAllocatedOn()}
                                </Header.Subheader>
                            </Header>

                            <Header as='h5'>Work Locations</Header>
                            <div>
                                <div>Primary - {this.renderWorkLocation(investigator.getPrimaryLocations())}</div>
                                <div>Secondary - {this.renderWorkLocation(investigator.getSecondaryLocations())}</div>
                                <div>Tertiary - {this.renderWorkLocation(investigator.getTertiaryLocations())}</div>
                            </div>
    
                            <Header as='h5'>Investigation Specifications</Header>
                            <LabelContainer>
                                {this.renderSpecifications(model.getInvestigationSpecifications())}
                            </LabelContainer>
    
                            <Header as='h5'>Evidence</Header>
                            <FileLabel.Group horizontal style={{wordBreak:"break-all"}}>
                                {this.renderEvidence(model.getEvidence())}
                            </FileLabel.Group >
                            <Header as='h5'>MRD Receipt</Header>
                            <FileLabel.Group horizontal style={{wordBreak:"break-all"}}>
                                {this.renderEvidence(model.getDocumentExpenseReceipt())}
                            </FileLabel.Group>
                        </Grid.Column>
                      
                        <Grid.Column width={10}>
                            <Form>
                            <Form.Dropdown
                            search
                            selection
                            label="Investigation Outcome"
                            disabled={this.state.isOutcomeYes} 
                            placeholder="Select Outcome"
                            options={this.toInvestigationOutcomeOptions(Object.values(constants.caseOutcome))}
                            value={this.state.outcome}
                            onChange={(_, { value }) => this.setState({ outcome: value as string })}
                        />
                                    {model.getOutcome() !== '' &&
                                    <>
                                    <span>Do You Agree With This Outcome if no then select no to change the outcome</span>
                                    <Form.Group inline>
                                        <Form.Radio
                                            label="Yes"
                                            checked={this.state.isOutcomeYes}
                                            onChange={() => this.setState({ isOutcomeYes: true })}
                                        />
                                        <Form.Radio
                                            label="No"
                                            checked={!this.state.isOutcomeYes}
                                            onChange={() => this.setState({ isOutcomeYes: false })}
                                        />
                                    </Form.Group>  
                                    </>
                                }


                                <Form.Input
                                    label='Case Portion'
                                    placeholder='e.g. 0.5'
                                    value={this.state.casePortion}
                                    onChange={(_, { value }) => {
                                        if (validator.casePortion.isValid(value) || value === '')
                                            this.setState({ casePortion: value })
                                    }} />
                                <LocationInput
                                    label={<Form.Field label='Investigation Location' />}
                                    value={this.state.location}
                                    
                                    stateDistrictRepo={this.stateDistrictRepo}
                                    onChange={value => {
                                        this.setState({ location: value })
                                        if (value !== null && value.state.trim().length !== 0 && value.district.trim().length !== 0) {
                                            let disableAllowanceInput = this.shouldDisableAllowanceInput(value)
                                            let allowance = 0
                                            if (disableAllowanceInput) 
                                                allowance = this.getAllowanceFromInvestigatorAllowance(value, this.props.model.getInvestigator())
                                            this.setState({ disableAllowanceInput, allowance: allowance.toString() })
                                        }
                                    }}/>
                                <Form.Input
                                    disabled={this.state.disableAllowanceInput}
                                    label='Allowance'
                                    placeholder='e.g. 300'
                                    value={this.state.allowance}
                                    onChange={(_, { value }) => {
                                        if (this.isNumber(value) || value === '')
                                            this.setState({ allowance: value })
                                    }} />

                                
                                <Form.Group>
                                    <Form.Input
                                        label='Approved Expense'
                                        placeholer='e.g. 250'
                                        value={this.state.approvedExpense}
                                        onChange={(_, { value }) => this.setExpenseState('approvedExpense', value)} />
    
                                    <Form.Input
                                        label='Approval Letter'
                                        type='file'
                                        accept='image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx'
                                        onChange={e => this.setState({ approvedExpenseFiles: this.toFileArray(e.target.files) })} />
                                </Form.Group>

    
                                <Form.Group>
                                    <Form.Input
                                        label='MRD Amount'
                                        placeholer='e.g. 250'
                                        value={this.state.documentExpense}
                                        disabled={model.getDocumentExpenseReceipt().length >=1}

                                        onChange={(_, { value }) => this.setExpenseState('documentExpense', value)}
                                         />
    
                                    <Form.Input
                                        label='MRD Receipt'
                                        type='file'
                                        accept='image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx'
                                        disabled={model.getDocumentExpenseReceipt().length >=1}
                                        onChange={e => this.setState({ documentExpenseFiles: this.toFileArray(e.target.files) })} />

                                </Form.Group>
    
    
                                <Form.Group>
                                    <Form.Input
                                        label='Extra Expense'
                                        placeholer='e.g. 250'
                                        value={this.state.extraExpense}
                                        onChange={(_, { value }) => this.setExpenseState('extraExpense', value)} />
    
                                    <Form.Input
                                        label='Expense Receipt'
                                        type='file'
                                        accept='image/*, .pdf, .doc, .docx, .csv, .xls, .xlsx'
                                        onChange={e => this.setState({ extraExpenseFiles: this.toFileArray(e.target.files) })} />
                                </Form.Group>

                                <Button 
                                    positive 
                                    floated='right'
                                    disabled={this.disableButton()}
                                    onClick={this.onFinishInvestigation}>
                                        Finish Investigation
                                </Button>
                            </Form>
                        </Grid.Column>
                        {/* Form */}
    
                    </Grid.Row>
                </Grid>
            </Segment>
          </>
        )
    }
    /**
     * Renders specifications as Labels
     * 
     * @param {string[]} specifications - Array of specifications
     * @returns {JSX.Element} 
     */
    private renderSpecifications = (specifications: string[]) => {
        return specifications.map(it => {
            return <Label>{it}</Label>
        })
    }


    /**
     * Renders evidence list
     * 
     * @param {URLResource[]} evidence - Array of evidence
     * @returns {JSX.Element}
     */
    private renderEvidence = (evidence: URLResource[]) => {
        return evidence.map(it => {
            return <FileLabel title={it.getFullName()} link={it.getURL()}/>
        })
    }


    /**
     * Converts FileList to file array
     * 
     * @param {FileList | null} filelist - File collection returned by the input
     * @returns {File[]} - Returns File array or empty array
     */
    private toFileArray = (filelist: FileList | null) => {
        const a: File[] = []
        if (filelist === null) {
            return a
        } else {
            for (let i = 0; i < filelist.length; i++)
                a.push(filelist[i])
            return a
        }
    }


    /**
     * Checks whether button should be disabled
     * 
     * @returns {boolean} - If true then button should be disabled
     */
    private disableButton = () => {
        return (
            this.state.loading ||
            this.state.outcome.trim().length === 0 ||
            this.state.casePortion.trim().length === 0 ||
            this.state.location === null ||
            this.state.location.state.trim().length === 0 ||
            this.state.location.district.trim().length === 0 ||
            (
                !this.state.disableAllowanceInput &&
                this.state.allowance.trim().length === 0
            ) ||
            // (
            //     this.state.documentExpense.trim().length === 0 ||
            //     this.state.documentExpense !== '0' &&
            //     this.state.documentExpenseFiles.length === 1
            // ) ||
            (
                this.state.approvedExpense.trim().length === 0 ||
                this.state.approvedExpense !== '0' &&
                this.state.approvedExpenseFiles.length === 0
            ) ||
            (
                this.state.extraExpense.trim().length === 0 ||
                this.state.extraExpense !== '0' &&
                this.state.extraExpenseFiles.length === 0
            )
        )
    }


    /**
     * Converts investigation outcome string[] to DropdownItemProp[]
     * 
     * @param {string[]} outcomeArray - Investigation outcome array
     * @returns {DropdownItemProp[]}
     */
    private toInvestigationOutcomeOptions = (outcomeArray: string[]) => {
        const options: DropdownItemProps[] = outcomeArray.map(it => {
            return { text: it, key: it, value: it }
        })
        return options
    }


    /**
     * Checks if the string is a positive number
     * 
     * @param {string} value - Value to be checked
     * @returnss {boolean}
     */
    private isNumber = (value: string) => {
        if (value.length === 0) return false
        return /^\d*\.?\d*$/.test(value)
    }


    /**
     * Sets expense value in state
     * 
     * @param {string} key - Expense which needs to be set
     * @param {string} value - The value which will be set
     */
    private setExpenseState = (key: string, value: string) => {
        let v = ''
        if (this.isNumber(value))
            v = value
        if (key === 'documentExpense')
            this.setState({ [key]: v })
        else if (key === 'approvedExpense')
            this.setState({ [key]: v })
        else if (key === 'extraExpense')
            this.setState({ [key]: v })
    }


    /**
     * Checks whether allowance field should be disabled or not
     * 
     * @param {Location} investigationLocation - Investigation Location
     * @returns {boolean}
     */
    private shouldDisableAllowanceInput = (investigationLocation: LocationInputValue): boolean => {
        const user = this.props.model.getInvestigator()
        if (User.isLocationIncluded(user.getPrimaryLocations(), investigationLocation))
            return true
        if (User.isLocationIncluded(user.getSecondaryLocations(), investigationLocation))
            return true
        if (User.isLocationIncluded(user.getTertiaryLocations(), investigationLocation))
            return true
        return false
    }



    /**
     * Renders Work Location of investigator
     * 
     * @param {Location} location - The value to be rendered
     * @returns {string}
     */
    private renderWorkLocation = (locations: Location[]): string => {
        return User.getDistricts(locations).join()
    }


    /**
     * Get Allowance Value from InvestigatorAllowance
     * 
     * @param {LocationInputValue} investigationLocation - The location for which value is to be get
     * @param {User} user
     * @returns {number} - Allowance
     */
    private getAllowanceFromInvestigatorAllowance = (
        investigationLocation: LocationInputValue,
        user: User
    ) => {
        if (this.state.investigatorAllowance === null)
            return 0

        if (User.isLocationIncluded(user.getPrimaryLocations(), investigationLocation))
            return this.state.investigatorAllowance.getPrimaryLocation()
        
        if (User.isLocationIncluded(user.getSecondaryLocations(), investigationLocation))
            return this.state.investigatorAllowance.getSecondaryLocation()

        if (User.isLocationIncluded(user.getTertiaryLocations(), investigationLocation))
            return this.state.investigatorAllowance.getTertiaryLocation()

        return 0
    }
}

export default React.memo(FinishInvestigationForm)