import React, { useEffect, useState } from 'react';
import { Table, Button } from 'semantic-ui-react';
import LocalStorage from '../../../lib/LocalStorage';
import config from '../../../common/repository/config';

interface ApiResponse {
  success: boolean;
  message: string | null;
  data: {
    total_item_count: number;
    items: Notification[];
  };
}

interface Notification {
  _id: string;
  for: string;
  type: string;
  title: string;
  message: string;
  receiver: string[];
  deleted: boolean;
  history_id: string | null;
  sender: string;
  metadata: {
    user_id: string;
    emp_id: string;
    email: string;
    name: string;
    contact_no: string;
  };
  readBy: string[];
  createdAt: string;
  updatedAt: string;
}

const LoginRequest: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
        if (!token) {
          setError('Authentication token is missing.');
          return;
        }

        const response = await fetch(`${config.apiBaseURL}/notification/listRequestLogin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });

        if (!response.ok) throw new Error('Failed to fetch login requests');

        const data: ApiResponse = await response.json();
        if (data.success && data.data?.items) {
          setNotifications(data.data.items);
        } else {
          setError('No notifications found.');
        }
      } catch (error) {
        setError('Error fetching login requests.');
        console.error('Error fetching login requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleAction = async (notification: Notification, action: 'approve' | 'decline') => {
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        setError('Authentication token is missing.');
        return;
      }

      const { user_id, emp_id, email } = notification.metadata;

      if (action === 'decline') {
        if (!user_id || !emp_id || !email) {
          throw new Error('Investigator Id, Emp Id, and Email are required for declining the request.');
        }
      }

      const response = await fetch(`${config.apiBaseURL}/notification/makeReadRequestLogin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({
          notificationId: notification._id,
          action,
          userId: user_id,
          empId:emp_id,
          email:email,
        }),
      });
     console.log(response,"uygu")
      if (!response.ok) throw new Error(`Failed to ${action} the request`);
      setNotifications((prev) => prev.filter((notif) => notif._id !== notification._id));
    } catch (error) {
      setError(`Error processing the ${action} action.`);
      console.error(`Error processing the ${action} action:`, error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="mrd-notification">
      <h2>Login Requests</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell>Sender</Table.HeaderCell>
            <Table.HeaderCell>Contact No</Table.HeaderCell>
            <Table.HeaderCell>Employee Id</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {notifications.map((notification) => (
            <Table.Row key={notification._id}>
              <Table.Cell>{notification.title}</Table.Cell>
              <Table.Cell>{notification.message}</Table.Cell>
              <Table.Cell>{notification.metadata.name}</Table.Cell>
              <Table.Cell>{notification.metadata.contact_no}</Table.Cell>
              <Table.Cell>{notification.metadata.emp_id}</Table.Cell>
         
              <Table.Cell>
                <Button
                  color="green"
                  onClick={() => handleAction(notification, 'approve')}
                >
                  Approve
                </Button>
                <Button
                  color="red"
                  onClick={() => handleAction(notification, 'decline')}
                >
                  Decline
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default LoginRequest;
