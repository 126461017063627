import ProtectedAPIRepository from './ProtectedAPIRepository'
import CaseDataMapper from '../datamapper/CaseDataMapper'
import Result from './Result'
import APIResponseModel from './APIResponseModel'
import CaseRM from '../responsemodel/CaseRM'
import AllocatedInvestigatorRM from '../responsemodel/AllocatedInvestigatorRM'
import AllocatedInvestigatorDataMapper from '../datamapper/AllocatedInvestigatorDataMapper'
import QCDataRM from '../responsemodel/QCDataRM'
import QCDataDataMapper from '../datamapper/QCDataDataMapper'
import CaseHistoryRM from '../responsemodel/CaseHistoryRM'
import CaseInvestigatorHistoryDataMapper from '../datamapper/CaseInvestigatorHistoryDataMapper'
import CaseInvestigatorHistoryRM from '../responsemodel/CaseInvestigatorHistoryRM'

import CaseServiceRM from '../responsemodel/CaseServiceRM'
import CaseServiseDataMapper from '../datamapper/CaseServiseDataMapper'
import CaseServiceDataRM from '../responsemodel/CaseServiceDataRM'
import CaseServiseDetailDataMapper from '../datamapper/CaseServicDetiailDataMapper'

import AttendentInfoDataMapper from '../datamapper/CaseServiceDetailDataMapper/AttendentInfoDataMapper'
import AttendentInfoDataRM from '../responsemodel/CaseServiceDataRM/AttendentInfoDataRM'
import DoctorPathologistsDataRM from '../responsemodel/CaseServiceDataRM/DoctorPathologistsDataRM'
import DoctorPathologistsDataMapper from '../datamapper/CaseServiceDetailDataMapper/DoctorPathologistsDataMapper'
import HospitalInfrastructureDataRM from '../responsemodel/CaseServiceDataRM/HospitalInfrastructureDataRM'
import HospitalInfrastructureDataMapper from '../datamapper/CaseServiceDetailDataMapper/HospitalInfrastructureDataMapper'
import InsuredInfoDataRM from '../responsemodel/CaseServiceDataRM/InsuredInfoDataRM'
import InsuredInfoDataMapper from '../datamapper/CaseServiceDetailDataMapper/InsuredInfoDataMapper'
import TreatingDoctorsDataRM from '../responsemodel/CaseServiceDataRM/TreatingDoctorsDataRM'
import TreatingDoctorsDataMapper from '../datamapper/CaseServiceDetailDataMapper/TreatingDoctorsDataMapper'

import CaseHistoryDataMapper from '../datamapper/CaseHistoryDataMapper'
import CaseCountDataMapper from '../datamapper/CaseCountDataMapper'
import CaseCountRM from '../responsemodel/CaseCountRM'
import { CancelToken } from 'axios'
import PerformanceEvaluationRM from '../responsemodel/PerformanceEvaluationRM'
import PerformanceEvaluationDataMapper from '../datamapper/PerformanceEvaluationDataMapper'

export default class CaseRepository extends ProtectedAPIRepository {
  private caseDataMapper = new CaseDataMapper()
  private allocatedInvestigatorDataMapper = new AllocatedInvestigatorDataMapper()
  private qcDataDataMapper = new QCDataDataMapper()
  private caseHistoryDataMapper = new CaseHistoryDataMapper()
  private caseCountDataMapper = new CaseCountDataMapper()
  private performanceEvaluationDataMapper = new PerformanceEvaluationDataMapper()

  private CaseServiseDataMapper = new CaseServiseDataMapper()
  private CaseServiseDetailDataMapper = new CaseServiseDetailDataMapper()
  private AttendentInfoDataMapper = new AttendentInfoDataMapper()
  private DoctorPathologistsDataMapper = new DoctorPathologistsDataMapper()
  private HospitalInfrastructureDataMapper = new HospitalInfrastructureDataMapper()
  private InsuredInfoDataMapper = new InsuredInfoDataMapper()
  private TreatingDoctorsDataMapper = new TreatingDoctorsDataMapper()
  private CaseInvestigatorHistoryDataMapper = new CaseInvestigatorHistoryDataMapper()

  getCases = async (ro?: GetCasesRequestObject) => {
    const response = await this.post<APIResponseModel<CaseRM>>('/case', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      console.log(data,)
      if (data.data !== null) {
        const cases = data.data.items.map((it) => this.caseDataMapper.map(it))
        return new Result.Success({
          items: cases,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  // remove = async (CaseId: string) => {
  //   const response = await this.delete<APIResponseModel<null>>(`/case/${CaseId}`)
  //   return response
  // }

  // getCasesList = async (ro?: GetCasesListRequestObject) => {
  //   const response = await this.post<APIResponseModel<CaseRM>>('/case/list', ro)
  //   console.log(response, "resoncelst")
  //   if (response instanceof Result.Success) {
  //     const data: APIResponseModel<CaseRM> = response.data
  //     if (data.data !== null) {
  //       const cases = data.data.items.map((it) => this.caseDataMapper.map(it))
  //       return new Result.Success({
  //         items: cases,
  //         totalItemCount: data.data.total_item_count,
  //       })
  //     } else {
  //       return new Result.Error(0, 'Data is null')
  //     }
  //   } else {
  //     return response
  //   }
  // }



  initateCase = async (ro: InitiateCaseRequestObject) => {
    const response = await this.put<APIResponseModel<CaseRM>>('/case/initiate', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  generateCase = async (ro: GenerateCaseRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.put<APIResponseModel<CaseRM>>('/case/generate', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateCase = async (ro: GenerateCaseRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.patch<APIResponseModel<CaseRM>>('/case', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  appendCase = async (ro: AppendCaseRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.patch<APIResponseModel<CaseRM>>('/case/append', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  removeDocument = async (ro: RemoveDocumentRequestObject) => {
    const response = await this.patch<APIResponseModel<CaseRM>>('/case/document/delete', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  removeInvestigationDocument = async (ro: RemoveInvestigatorDocumentRequestObject) => {
    const response = await this.patch<APIResponseModel<AllocatedInvestigatorRM>>('/case/allocate/evidence/remove', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<AllocatedInvestigatorRM> = response.data
      if (data.data !== null) {
        const allocatedInvestigators = data.data.items.map((it) => this.allocatedInvestigatorDataMapper.map(it))
        return new Result.Success({
          items: allocatedInvestigators,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  allocateInvestigators = async (ro: AllocateInvestigatorsRequestObject) => {
    const response = await this.put<APIResponseModel<CaseRM>>('/case/allocate', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getAllocatedInvestigators = async (ro: GetAllocatedInvesitgatorsRequestObject) => {
    const response = await this.post<APIResponseModel<AllocatedInvestigatorRM>>('/case/allocate', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<AllocatedInvestigatorRM> = response.data
      if (data.data !== null) {
        const allocatedInvestigators = data.data.items.map((it) => this.allocatedInvestigatorDataMapper.map(it))
        return new Result.Success({
          items: allocatedInvestigators,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  uploadEvidence = async (ro: UploadEvidenceRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.put<APIResponseModel<AllocatedInvestigatorRM>>('/case/allocate/evidence', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<AllocatedInvestigatorRM> = response.data
      if (data.data !== null) {
        return new Result.Success(this.allocatedInvestigatorDataMapper.map(data.data.items[0]))
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
  

  finishInvestigation = async (ro: FinishInvestigationRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.put<APIResponseModel<AllocatedInvestigatorRM>>('/case/allocate/finish', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<AllocatedInvestigatorRM> = response.data
      if (data.data !== null) {
        const allocatedInvestigator = this.allocatedInvestigatorDataMapper.map(data.data.items[0])
        return new Result.Success(allocatedInvestigator)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  enableReInvestigation = async (allocation_id: string) => {
    
    const response = await this.patch<APIResponseModel<AllocatedInvestigatorRM>>(`/case/allocate/investigation/reopen`, { allocation_id: allocation_id })
    if (response instanceof Result.Success) {
      const data: APIResponseModel<AllocatedInvestigatorRM> = response.data
      if (data.data !== null) {
        
        const allocatedInvestigators = data.data.items.map((it) => this.allocatedInvestigatorDataMapper.map(it))
        return new Result.Success({
          items: allocatedInvestigators,
          totalItemCount: data.data.total_item_count,
          
        })
        
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  foldCase = async (ro: FoldCaseRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.patch<APIResponseModel<CaseRM>>('/case/fold', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  addInsuredDetail = async (ro: AddInsuredDetailRequestObject) => {
    const response = await this.patch<APIResponseModel<CaseRM>>('/case/changeType', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  pushback = async (ro: PushBackRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.put<APIResponseModel<CaseRM>>('/case/pushBack', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  addRemark = async (ro: AddRemarkRequestObject) => {
    const response = await this.put<APIResponseModel<CaseRM>>('/case/remarks', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  addReminder = async (ro: AddReminderRequestObject) => {
    const response = await this.put<APIResponseModel<CaseRM>>('/case/remainders', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  closeReminder = async (ro: CloseReminderRequestObject) => {
    const response = await this.post<APIResponseModel<CaseRM>>('/case/remainders', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getQCData = async (ro: GetQCDataRequestObject) => {
    const response = await this.post<APIResponseModel<QCDataRM>>('/case/allocation/claimanalyst', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<QCDataRM> = response.data
      if (data.data !== null) {
        const qcData = this.qcDataDataMapper.map(data.data.items[0])
        return new Result.Success(qcData)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  performQualityCheck = async (ro: PerformQualityCheckRequestObject) => {
    const response = await this.put<APIResponseModel<CaseRM>>('/case/check', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  updateQualityCheck = async (ro: PerformQualityCheckRequestObject) => {
    const response = await this.put<APIResponseModel<CaseRM>>('/case/allocation_update', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  closeCase = async (ro: CloseCaseRequestObject) => {
    const formData = this.constructFormData(ro)
    const response = await this.patch<APIResponseModel<CaseRM>>('/case/close', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  performGrading = async (ro: PerformGradingRequestObject) => {
    const response = await this.put<APIResponseModel<CaseRM>>('/case/grade', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  remove = async (caseId: string) => {
    const response = await this.delete<APIResponseModel<null>>(`/case/${caseId}`)
    if (response instanceof Result.Success) {
      return new Result.Success(null)
    } else {
      return response
    }
  }

  withdraw = async (ro: WithdrawRequestObject) => {
    const response = await this.patch<APIResponseModel<CaseRM>>('/case/withdraw', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  OpenReInvestigation = async (ro: OpenReInvestigationRequestObject) => {
    const response = await this.post<APIResponseModel<CaseRM>>('/case/openReInvestigation', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseRM> = response.data
      if (data.data !== null) {
        const c = this.caseDataMapper.map(data.data.items[0])
        return new Result.Success(c)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  removeAllocatedInvestigator = async (allocationId: string) => {
    const response = await this.delete<APIResponseModel<null>>(`/case/allocate/${allocationId}`)
    if (response instanceof Result.Success) {
      return new Result.Success(null)
    } else {
      return response
    }
  }

  getLastActivityUser = async (caseId: string) => {
    const response = await this.get<APIResponseModel<CaseHistoryRM>>(`/case/lastActivityUser/${caseId}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseHistoryRM> = response.data
      if (data.data !== null) {
        return response.data.data
      }
    }
  }
  getCaseServices = async (caseId: string) => {
    const response = await this.get<APIResponseModel<CaseServiceRM>>(`/case/servicesWeb/${caseId}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseServiceRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.CaseServiseDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
  // getCaseServices = async (caseId: string) => {
  //   const response = await this.get<APIResponseModel<null>>(`/case/servicesWeb/${caseId}`)
  //   if (response instanceof Result.Success) {
  //     // console.log(response.data.data?.items, "jjjjjjiiiiiiiiiiiiiiiiii")
  //     const data: APIResponseModel<null> = response.data
  //     if (data.data !== null) {
  //       return response.data.data
  //     }
  //   }
  // }
  getCaseServicesData = async (caseId: string) => {
    const response = await this.get<APIResponseModel<CaseServiceDataRM>>(`/case/servicesData/${caseId}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseServiceDataRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.CaseServiseDetailDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
  getAttendentInfoData = async (caseId: string) => {
    const response = await this.get<APIResponseModel<AttendentInfoDataRM>>(`/case/getAttendentInfoData/${caseId}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<AttendentInfoDataRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.AttendentInfoDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
  getDoctorPathologistsData = async (caseId: string) => {
    const response = await this.get<APIResponseModel<DoctorPathologistsDataRM>>(`/case/getPathologistData/${caseId}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<DoctorPathologistsDataRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.DoctorPathologistsDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
  getHospitalInfrastructureData = async (caseId: string) => {
    const response = await this.get<APIResponseModel<HospitalInfrastructureDataRM>>(`/case/getHospitalInfra/${caseId}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<HospitalInfrastructureDataRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.HospitalInfrastructureDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
  getInsuredInfoData = async (caseId: string) => {
    const response = await this.get<APIResponseModel<InsuredInfoDataRM>>(`/case/getInsurednfoData/${caseId}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<InsuredInfoDataRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.InsuredInfoDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
  getTreatingDoctorsData = async (caseId: string) => {
    const response = await this.get<APIResponseModel<TreatingDoctorsDataRM>>(`/case/getTreatingDoctorData/${caseId}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<TreatingDoctorsDataRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.TreatingDoctorsDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getCaseHistory = async (caseId: string) => {
    const response = await this.get<APIResponseModel<CaseHistoryRM>>(`/case/history/${caseId}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseHistoryRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.caseHistoryDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getCaseDataHistory = async (caseId: string) => {
    const response = await this.get<APIResponseModel<CaseInvestigatorHistoryRM>>(`/case/caseDetailData/${caseId}`)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseInvestigatorHistoryRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.CaseInvestigatorHistoryDataMapper.map(it))
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getCaseCount = async (ro: GetCaseCountRequestObject, cancelToken?: CancelToken) => {
    const response = await this.post<APIResponseModel<CaseCountRM>>('/case/count', ro, cancelToken)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<CaseCountRM> = response.data
     
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.caseCountDataMapper.map(it))
        console.log(data)
        return new Result.Success({
          items,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  performanceEvaluation = async (ro: PerformanceEvaluationRequestObject, cancelToken?: CancelToken) => {
    const response = await this.post<APIResponseModel<PerformanceEvaluationRM>>('/case/evaluationReport', ro, cancelToken)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<PerformanceEvaluationRM> = response.data
      if (data.data !== null) {
        return new Result.Success(this.performanceEvaluationDataMapper.map(data.data.items[0]))
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getClaimSize = async (claim_number: string) => {
    const response = await this.post<APIResponseModel<any>>('/case/get-size', {
      claim_number,
    })
    if (response instanceof Result.Success) {
      const data: APIResponseModel<any> = response.data
      if (data.data !== null) {
        return new Result.Success(data.data.items)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  getEvidenceSize = async (claim_number: string) => {
    const response = await this.post<APIResponseModel<any>>('/case/getEvidenceSize', { claim_number })
    if (response instanceof Result.Success) {
      const data: APIResponseModel<any> = response.data
      if (data.data !== null) {
        return new Result.Success(data.data.items)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {        
      return response
    }
  }

  AddWaterMark = async (case_id: string) => {
    const response = await this.post<APIResponseModel<any>>('/cases/addWaterMark', { case_id })
    if (response instanceof Result.Success) {
      const data: APIResponseModel<any> = response.data
      if (data.data !== null) {
        return new Result.Success(data.data.items)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

}

export interface GetCasesRequestObject {
  id?: string
  user?: { id: any; role: any; reportee:any }
  claim_number?: string
  exactMatch?: boolean
  state?: string
  case_category?: string
  client_id?: string
  location?: { state?: string; district?: string }
  hospital_id?: string
  case_type_id?: string
  is_withdrawn?: boolean
  insured_name?: string
  doctor_name?: string
  from?: string
  to?: string
  search?: string
  limit?: number
  offset?: number
  get_by_closed_date?: boolean
  closed_cases_active?: boolean
  reinvestigation?: boolean
  pushback?: boolean
  checkForGeneration?: boolean
}

export interface GetCasesListRequestObject {
  id?: string
  user?: { id: string; role: string; reportee:string}
  claim_number?: string
  exactMatch?: boolean
  state?: string
  case_category?: string
  client_id?: string
  location?: { state?: string; district?: string }
  hospital_id?: string
  case_type_id?: string
  is_withdrawn?: boolean
  insured_name?: string
  doctor_name?: string
  from?: string
  to?: string
  search?: string
  limit?: number
  offset?: number
  get_by_closed_date?: boolean
  closed_cases_active?: boolean
}

export interface InitiateCaseRequestObject {
  claim_number: string
  inward_date_time: string
  tpa?: string
  insurance_company?: string
  insurance_company_name?: string
  case_type: string
  case_category: string
  subject_line: string
  insured_location: { state: string; district: string }
}

export interface GenerateCaseRequestObject {
  id?: string
  case_category: string
  case_type: string
  inward_date_time: string
  claim_number: string
  tpa?: string
  insurance_company?: string
  insurance_company_name?: string
  insured_name: string
  insured_location?: { state: string; district: string }
  insured_phone?: string
  insured_address?: string
  hospital: string
  policy: string
  policy_inception: string
  policy_number: string
  available_TAT: number
  subject_line: string
  triggers: string
  documents: File[]
}

export interface AppendCaseRequestObject {
  id: string
  triggers?: string
  documents?: File[]
}

export interface RemoveDocumentRequestObject {
  id: string
  document_url: string
}

export interface RemoveInvestigatorDocumentRequestObject {
  allocation_id: string
  document_url: string
}

export interface AllocateInvestigatorsRequestObject {
  case_id: string
  allocations: { investigator_id: string; specifications: string[] }[]
  // services: []
  // required_documents: []
}

export interface UploadEvidenceRequestObject {
  allocation_id: string
  evidence: File[]
}

export interface GetAllocatedInvesitgatorsRequestObject {
  case_id: string
}

export interface FinishInvestigationRequestObject {
  allocation_id: string
  outcome: string
  case_portion: number
  investigation_location: { state: string; district: string }
  allowance: number
  document_expense: number
  document_expense_receipt: File[]
  approved_expense: number
  approved_expense_receipt: File[]
  extra_expense: number
  extra_expense_receipt: File[]
}

export interface FoldCaseRequestObject {
  case_id: string
  outcome: string
  case_type: string
  case_category: string
  outcome_report: File
  repudiation_evidences?: File[]
  query_trigger_document?: File
  repudiation_ground?: string
}

export interface AddInsuredDetailRequestObject {
  case_id: string
  insured_location: { state: string; district: string }
  insured_address: string
  insured_phone: string
  case_type: string
  case_category: string
}

export interface PushBackRequestObject {
  case_id: string
  triggers: string
  documents?: File[]
}

export interface AddRemarkRequestObject {
  case_id: string
  remark: string
}

export interface AddReminderRequestObject {
  case_id: string
  remainder: {
    text: string
    valid_upto: string
  }
}

export interface CloseReminderRequestObject {
  case_id: string
  remainder_id: string
}

export interface CloseCaseRequestObject {
  case_id: string
  final_report: File
  final_remarks: string
  major_observations:string
  // insured_aadhaar_card: string
  // bed_count: string
  // icu: boolean
  // ot: boolean
  // indoor_pharmacy: boolean
  // indoor_lab: boolean
  // indoor_xray: boolean
  // indoor_usg: boolean
  // indoor_mri: boolean
  // doctor: {
  //   name: string
  //   qualification: string
  //   registration_number: string
  //   council_name: string
  //   verified: boolean
  // }
  // pathologist: {
  //   name: string
  //   qualification: string
  //   registration_number: string
  //   council_name: string
  //   lab_name: string
  //   verified: boolean
  // }
}

export interface GetQCDataRequestObject {
  case_id: string
}

export interface PerformQualityCheckRequestObject {
  case_id: string
  allocations: {
    head_id: string
    team: {
      allocation_id: string
      investigator_id: string
      case_portion: string
      outcome: string
    }[]
    grade_report: {
      allocation_hygiene: number
      report_quality: number
      outcome: number
      tat: number
    }
    feedback?: string
  }[]
}

export interface PerformGradingRequestObject {
  case_id: string
  allocations: {
    claim_analyst: string
    team: string[]
    allocation_hygiene: number
    report_quality: number
    outcome: number
    tat: number
    feedback?: string
  }[]
}

export interface WithdrawRequestObject {
  case_id: string
}

export interface OpenReInvestigationRequestObject {
  id: string
}

export interface GetCaseCountRequestObject {
  showByState?: boolean
  case_category?: string
  state?: string
  hospital_id?: string
  case_type_id?: string
  is_withdrawn?: boolean
  location?: { state?: string; district?: any }
  client_id?: string
  user?: {
    id: string
    role: string
    reportee:any
  }
  search?: string
  claim_number?: string
  insured_name?: string
  doctor_name?: string
  from?: string
  to?: string
  get_by_closed_date?: boolean
  reinvestigation?: boolean
  pushback?: boolean
}

export interface PerformanceEvaluationRequestObject {
  case_category?: string
  hospital_id?: string
  case_type_id?: string
  is_withdrawn?: boolean
  location?: { state: string; district: string }
  client_id?: string
  view_type?: string
  user?: { id: string; role: string }
  search?: string
  from: string
  to: string
  evaluation_by_closed_date?: boolean
}
