import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './MrdNotification.css';
import LocalStorage from '../../../lib/LocalStorage';
import config from '../../../common/repository/config';
import Button from '../../elements/Button';
import { Table, Pagination, Dropdown } from 'semantic-ui-react';

interface ApiResponse {
  success: boolean;
  message: string | null;
  data: {
    total_item_count: number;
    items: Notification[];
  };
}
interface Notification {
  _id: string;
  for: string;
  type: string;
  title: string;
  message: string;
  receiver: string[];
  deleted: boolean;
  history_id: string | null;
  type_id: string;
  sender: string;
  readBy: string[];
  createdAt: string;
  updatedAt: string;
  metadata: {
    case_category: string;
    case_type: string;
    claim_number: string;
    insured_name: string;
    hospital: {
      id: string;
      name: string;
      phone: string | null;
      email: string | null;
    };
    tpa: {
      insurance_company: string;
      tpa: string;
    };
    insurance_company: string;
    insured_location: {
      state: string;
      district: string;
    };
    closed_on: string;
    inward_date_time: string;
    remainders: any[];
  };
}

const ListFinishedInvestigation: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error' | null>(null);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const history = useHistory();

  const pageOptions = [
    { key: 5, text: '5', value: 5 },
    { key: 10, text: '10', value: 10 },
    { key: 15, text: '15', value: 15 },
    { key: 20, text: '20', value: 20 },
  ];

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
        if (!token) {
          setToastMessage('Authentication token is missing.');
          setToastType('error');
          return;
        }

        const response = await fetch(`${config.apiBaseURL}/notification/listFinishedInvestigation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });

        if (!response.ok) throw new Error('Failed to fetch finished investigation notifications');

        const data: ApiResponse = await response.json();
        if (data.success && data.data?.items) {
          setNotifications(data.data.items);
        } else {
          setToastMessage('No notifications found.');
        }
      } catch (error) {
        setToastMessage('Error fetching finished investigation notifications.');
        setToastType('error');
        console.error('Error fetching finished investigation notifications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (toastMessage) {
      setToastMessage(null);
      setToastType(null);
    }
  }, [toastMessage, toastType]);

  const handlePaginationChange = (_: any, { activePage }: any) => {
    setActivePage(activePage);
  };

  const handleItemsPerPageChange = (_: any, { value }: any) => {
    setItemsPerPage(value as number);
    setActivePage(1); 
  };

  const paginatedNotifications = notifications.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mrd-notification">
      <h2>All Finished Case From App Listing</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Reminder</Table.HeaderCell>
            <Table.HeaderCell>Case Category</Table.HeaderCell>
            <Table.HeaderCell>Case Type</Table.HeaderCell>
            <Table.HeaderCell>Running TAT</Table.HeaderCell>
            <Table.HeaderCell>Re-investigation TAT</Table.HeaderCell>
            <Table.HeaderCell>Claim Number</Table.HeaderCell>
            <Table.HeaderCell>TPA/Company</Table.HeaderCell>
            <Table.HeaderCell>Insured Name</Table.HeaderCell>
            <Table.HeaderCell>Insured District</Table.HeaderCell>
            <Table.HeaderCell>Hospital</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {paginatedNotifications.length === 0 ? (
            <Table.Row>
              <Table.Cell colSpan="11" textAlign="center">
                No finished investigations found.
              </Table.Cell>
            </Table.Row>
          ) : (
            paginatedNotifications.map((notification) => (
              <Table.Row key={notification._id}>
                <Table.Cell>{notification.metadata.remainders.length > 0 ? notification.metadata.remainders.map((rem, index) => <div key={index}>{rem.text || 'No text available'}</div>) : 'N/A'}</Table.Cell>
                <Table.Cell>{notification.metadata.case_category || 'N/A'}</Table.Cell>
                <Table.Cell>{notification.metadata.case_type || 'N/A'}</Table.Cell>
                <Table.Cell>{notification.createdAt}</Table.Cell>
                <Table.Cell>{notification.metadata.closed_on || 'N/A'}</Table.Cell>
                <Table.Cell>{notification.metadata.claim_number || 'N/A'}</Table.Cell>
                <Table.Cell>{notification.metadata.insurance_company} {notification.metadata.tpa ? `${notification.metadata.tpa.insurance_company} - ${notification.metadata.tpa.tpa}` : 'Not Available'}</Table.Cell>
                <Table.Cell>{notification.metadata.insured_name || 'N/A'}</Table.Cell>
                <Table.Cell>{notification.metadata.insured_location?.district || 'N/A'}</Table.Cell>
                <Table.Cell>{notification.metadata.hospital?.name || 'N/A'}</Table.Cell>
                <Table.Cell>
                  <Button style={{ backgroundColor: 'rgb(222, 255, 139)' }} onClick={() => history.push(`/case/view/${notification.type_id}`)}>
                    <i className="eye icon"></i> Open
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>

      {/* Pagination */}
        <div className="pagination-container"
         style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "20px",
        }}
        >
           <div className="pagination-options">
        <span>Show </span>
        <Dropdown 
          inline 
          options={pageOptions} 
          value={itemsPerPage} 
          onChange={handleItemsPerPageChange} 
        />
        <span> per page</span>
      </div>
        <Pagination
          activePage={activePage}
          onPageChange={handlePaginationChange}
          totalPages={Math.ceil(notifications.length / itemsPerPage)}
        />
        {/* Page count */}
        <div className="page-count">
          {/* <span>
            {((activePage - 1) * itemsPerPage) + 1} - {Math.min(activePage * itemsPerPage, notifications.length)} of {notifications.length} 90
          </span> */}
        </div>
      </div>
    </div>
    // </div>
  );
};


export default ListFinishedInvestigation;
