import React, { useEffect, useState } from "react";
import { Table, Button, Loader, Header, Icon, Modal, Form, Grid, Dropdown } from "semantic-ui-react";
import config from "../../../../common/repository/config";
import LocalStorage from "../../../../lib/LocalStorage";
import Permissions from './Permissions';
import permissionlist from './permissionlist';
import { toast } from "../../../common/Toast";
interface UserRole {
  id: string;
  name: string | undefined;
  permissions: string[];
  backend_permissions: string[];
  reportee: string[];
  level: number;
}
const UserRoleListing: React.FC = () => {
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedRole, setSelectedRole] = useState<UserRole | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [backendPermissions, setBackendPermissions] = useState<string[]>([]);
  const [selectedHead, setSelectedHead] = useState<number>();
  const [reporteeOptions, setReporteeOptions] = useState<{ key: string; value: string; text: string }[]>([]);
  const [reportee, setReportee] = useState<string[]>([""]);
  // const [supervisor,setSupervisor]=useState()

  const fetchUserRoles = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        setError("Authentication token is missing.");
        setLoading(false);
        return;
      }

      const response = await fetch(`${config.apiBaseURL}/userRole`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }

      const data = await response.json();
      const items: UserRole[] = data?.data?.items || [];
      setUserRoles(items);
    } catch (e) {
      console.error("Error fetching user roles:", e);
      setError("Failed to load user roles.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchReporteeData = async () => {
      try {
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
        if (!token) {
          throw new Error('Authentication token is missing.');
        }

        const response = await fetch(`${config.apiBaseURL}/userRole/reportee`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });

        const data = await response.json();

        console.log("API Response:", data);

        if (response.ok) {
          if (data.data && data.data.items) {
            console.log("Items:", data.data.items);
            const dropdownOptions = Object.entries(data.data.items).map(([key, value]) => {
              return {
                key: key,
                value: value as string,
                text: value as string,
              };
            });

            console.log("Dropdown Options (Array):", dropdownOptions);

            setReporteeOptions(dropdownOptions);
          } else {
            throw new Error('No items found in response.');
          }
        } else {
          throw new Error(data.message || 'Failed to fetch reportee data.');
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred while fetching reportee data.');
      }
    };

    fetchReporteeData();
  }, []);
  const handleEdit = async (roleId: string) => {
    setLoading(true);

    setError(null);
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        setError("Authentication token is missing.");
        setLoading(false);
        return;
      }

      const response = await fetch(`${config.apiBaseURL}/userRole/${roleId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch role: ${response.statusText}`);
      }
      const data = await response.json();
      console.log("data", data)
      const role = data?.data?.items[0];
      const dropdownOptions = Object.entries(data.data.items).map(([key, value]) => ({
        key,
        value: key,
        text: String(value),
      }));

      setReporteeOptions(reporteeOptions);
      if (role) {
        setSelectedRole(role);
        const previousLevel = role.level - 1;
        const previousHead = userRoles.filter(i => i.level == previousLevel);
        setSelectedHead(previousHead[0].level);
        setPermissions(role.permissions);
        setReportee(role.reportee);
        setBackendPermissions(role.backend_permissions);
        setIsEditing(true);
      }
    } catch (e) {
      console.error("Error fetching user role:", e);
      setError("Failed to load user role.");
    } finally {
      setLoading(false);
    }
  };
  const handleReporteeChange = (e: any, { value }: any) => {
    const filteredValue = value ? value.filter((item: any) => item !== "") : [];
    setReportee(filteredValue);
  };
  const handleSave = async () => {
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        setError("Authentication token is missing.");
        return;
      }

      const keyValueMap = {
        Location: "State/District",
        Client: "Client",
        Case: "CaseCategory",
        Desig: "Designation"
      };


      const selectedReporteeKeys = Array.isArray(reportee)
        ? reportee
          .filter((item) => item !== null)
          .filter((item) => Object.values(keyValueMap).includes(item))
          .map((item) =>
            Object.keys(keyValueMap).find((key) => keyValueMap[key as keyof typeof keyValueMap] === item)
          )
          .filter((key): key is string => key !== undefined)
        : ["No Supervisor"];
      console.log("Final Reportee Array:", selectedReporteeKeys);
      setLoading(true);
      const requestBody: any = {
        id: selectedRole?.id,
        name: selectedRole?.name,
        level: selectedHead,
        permissions,
        backend_permissions: backendPermissions,
        
      };
      if (selectedReporteeKeys.length > 0) {
        requestBody.reportee = selectedReporteeKeys;
      }else{
        requestBody.reportee = [];
      }
      const response = await fetch(`${config.apiBaseURL}/userRole`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        toast.success("User Role Updated Successfully");
        setIsEditing(false);
        const updatedRoles = userRoles.map((role) =>
          role.id === selectedRole?.id
            ? {
              ...role,
              permissions,
              backend_permissions: backendPermissions,
              ...(selectedReporteeKeys.length > 0 && { reportee: selectedReporteeKeys }),
            }
            : role
        );
        setUserRoles(updatedRoles);
      } else {
        const responseText = await response.text();
        console.error("API Error:", response.status, responseText);
        toast.error("Unable to Update User Role");
      }
    } catch (e) {
      console.error("Error updating user role:", e);
      setError("Failed to update user role.");
    } finally {
      setLoading(false);
    }
  };
  //Delete User Role *****
  // const deleteUserRole = async (roleId: string) => {
  //   try {
  //     const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
  //     if (!token) {
  //       throw new Error('Authentication token is missing.');
  //     }

  //     const response = await fetch(`${config.apiBaseURL}/userRole/${roleId}`, {
  //       method: 'DELETE',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'x-access-token': token,
  //       },
  //     });

  //     const data = await response.json();
  //     console.log("API Response:", data);

  //     if (response.ok) {
  //       toast.success("User Role Deleted Successfully");
  //       // Remove the deleted role from the state
  //       setUserRoles(userRoles.filter(role => role.id !== roleId));
  //     } else {
  //       toast.error(data.message);
  //     }
  //   } catch (err) {
  //     setError(err instanceof Error ? err.message : 'An error occurred while deleting the user role.');
  //   }
  // };
  useEffect(() => {
    fetchUserRoles();
    // deleteUserRole();
  }, []);
  function handleFrontendPermissionToggle(permission: string, operation: 'add' | 'remove'): void {
    setPermissions(prevPermissions => {
      const newPermissions = [...prevPermissions];
      if (operation === 'add' && !newPermissions.includes(permission)) {
        newPermissions.push(permission);
      } else if (operation === 'remove') {
        const index = newPermissions.indexOf(permission);
        if (index > -1) {
          newPermissions.splice(index, 1);
        }
      }
      return newPermissions;
    });
  }
  const handleBackendPermissionToggle = (permission: string, operation: 'add' | 'remove') => {
    setBackendPermissions(prevPermissions =>
      operation === 'add' ? [...prevPermissions, permission] : prevPermissions.filter(p => p !== permission)
    );
  };
  return (
    <div style={{ padding: "20px" }}>
      <Header as="h2">

        User Role Listing
      </Header>

      {loading ? (
        <Loader active inline="centered" content="Loading User Roles..." />
      ) : error ? (
        <div style={{ color: "red", marginBottom: "20px" }}>{error}</div>
      ) : (
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Role Name</Table.HeaderCell>
              <Table.HeaderCell>Supervisor</Table.HeaderCell>
              <Table.HeaderCell>Level</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {userRoles.length > 0 ? (
              userRoles.map((role) => (
                <Table.Row key={role.id}>
                  <Table.Cell>{role.name}</Table.Cell>
                  <Table.Cell>
                    {Array.isArray(role.reportee)
                      ? role.reportee.length > 0
                        ? role.reportee.join(",")
                        : "No supervisor"
                      : role.reportee || "No supervisor"}

                  </Table.Cell>

                  <Table.Cell>{role.level}</Table.Cell>
                  <Table.Cell>

                    <Button style={{ borderRadius: "5.285714rem" }} color="blue" onClick={() => handleEdit(role.id)}>
                      <i className="edit icon"></i>
                    </Button>

                    {/* {<Button style={{ borderRadius: "5.285714rem" }} color="blue" onClick={() => deleteUserRole(role.id)}>
                      <Icon name='trash' />
                    </Button>} */}

                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6}>No user roles found.</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      )}
      {/* Modal for editing user role */}
      <Modal open={isEditing} onClose={() => setIsEditing(false)} closeIcon>
        <Modal.Header>Edit User Role</Modal.Header>
        <Modal.Content>
          {selectedRole && (
            <>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <label>Role Name</label>
                      <Form.Input
                        value={selectedRole?.name || ""}
                        onChange={(e) => setSelectedRole({ ...selectedRole!, name: e.target.value })}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <label>Supervisor</label>
                      <Dropdown
                        placeholder="Select Reportee"
                        fluid
                        multiple
                        selection
                        value={reportee}
                        options={reporteeOptions}
                        onChange={handleReporteeChange}
                      />

                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <label>Level</label>
                      <Form.Input
                        type="number"
                        value={selectedRole?.level || ""}
                        onChange={(e) => setSelectedRole({ ...selectedRole!, level: parseInt(e.target.value, 10) })}
                        disabled
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <label>Select Head(For Herarchy Purpose Only)</label>
                      <Form.Field>
                        <Dropdown
                          placeholder="Select Head"
                          fluid
                          selection
                          options={userRoles.map((role) => ({
                            key: role.id,
                            text: role.name,
                            value: role.level,
                          }))}
                          value={selectedHead}
                          onChange={(e, { value }) => {
                            setSelectedHead(value as number);
                          }}
                        />
                      </Form.Field>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>

                {/* Add Select Head dropdown */}
                <Grid.Row>
                </Grid.Row>
              </Grid>
              <h4 style={{ marginTop: "20px" }}>Permissions</h4>
              <Permissions
                list={permissionlist.frontend}
                permissions={permissions}
                onPermissionToggle={handleFrontendPermissionToggle}
              />
              <Permissions
                list={permissionlist.backend}
                permissions={backendPermissions}
                onPermissionToggle={handleBackendPermissionToggle}
              />
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleSave}>
            Save
          </Button>
          <Button color="red" onClick={() => setIsEditing(false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default UserRoleListing;

