import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Form,
  Header,
  Icon,
  Button,
  Input,
  Segment,
  Radio,
} from "semantic-ui-react";
import CaseListPageContext from "./CaseListPageContext";
import DateTimePicker from "../../../app/DateTimePicker";
import constants from "../../../../common/constants";

const caseStateOptions = [
  {
    text: "Pending Generation",
    value: constants.caseStates.initiated,
    key: "1",
  },
  {
    text: "Pending Allocation",
    value: constants.caseStates.generated,
    key: "2",
  },
  { text: "Open", value: constants.caseStates.open, key: "3" },
  { text: "Pending QC", value: constants.caseStates.fold, key: "4" },
  { text: "Pending I. Closure", value: constants.caseStates.checked, key: "5" },
  { text: "Pending Grading", value: constants.caseStates.close, key: "6" },
  { text: "Graded", value: constants.caseStates.graded, key: "7" },
];

function Filters() {
  const ctx = useContext(CaseListPageContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [claimNumber, setClaimNumber] = useState("");
  const [insuredName, setInsuredName] = useState("");
  const [doctorName, setDoctorName] = useState("");
  let data: { key: string; value: any }[] = [];
  if (ctx.filters.search)
    data.push({ key: "search", value: ctx.filters.search });
  if (ctx.filters.to) data.push({ key: "to", value: ctx.filters.to });
  if (ctx.filters.from) data.push({ key: "from", value: ctx.filters.from });
  if (ctx.filters.doctorName)
    data.push({ key: "doctorName", value: ctx.filters.doctorName });
  if (ctx.filters.insuredName)
    data.push({ key: "insuredName", value: ctx.filters.insuredName });
  if (ctx.filters.hospital)
    data.push({ key: "hospital", value: ctx.filters.hospital });
  if (ctx.filters.claimNumber)
    data.push({ key: "claimNumber", value: ctx.filters.claimNumber });
  if (ctx.filters.client)
    data.push({ key: "client", value: ctx.filters.client });
  if (ctx.filters.caseType)
    data.push({ key: "caseType", value: ctx.filters.caseType });
  if (ctx.filters.category)
    data.push({ key: "category", value: ctx.filters.category });
  if (ctx.filters.user) data.push({ key: "user", value: ctx.filters.user });

  if (ctx.filters.userRole) {
    const selected = ctx.options.userRoles.find((role) => role.value === ctx.filters.userRole);
    data.push({ key: "userRole", value: ctx.filters.userRole });
    console.log(selected,)
  }
  


  if (ctx.filters.states)
    data.push({ key: "states", value: ctx.filters.states });

  if (ctx.filters.district)
    data.push({ key: "district", value: ctx.filters.district });
  if (ctx.filters.getByClosedDate)
    data.push({ key: "getByClosedDate", value: ctx.filters.getByClosedDate });

  if (ctx.filters.getReinvestigation)
    data.push({ key: "reinvestigation", value: ctx.filters.getReinvestigation });

  if (ctx.filters.getPushback)
    data.push({ key: "pushback", value: ctx.filters.getPushback });

  if (data.length > 0)

    localStorage.setItem("caseFilters", JSON.stringify(data));

  useEffect(() => {
    if (localStorage.getItem("caseFilters")) {
      let data = JSON.parse(localStorage.getItem("caseFilters")!);
      data.forEach((it: { key: string; value: any }) => {
        if (it.key == "search") ctx.filters.setSearch(it.value);
        if (it.key == "userRole") ctx.filters.setUserRole(it.value);
        if (it.key == "reportee") ctx.filters.setReportee(it.value);
         
        if (it.key == "user") ctx.filters.setUser(it.value);
        if (it.key == "category") ctx.filters.setCategory(it.value);
        if (it.key == "caseType") ctx.filters.setCaseType(it.value);
        if (it.key == "client") ctx.filters.setClient(it.value);
        if (it.key == "states") ctx.filters.setStates(it.value);
        if (it.key == "district") ctx.filters.setDistrict(it.value);
        if (it.key == "claimNumber") ctx.filters.setClaimNumber(it.value);
        if (it.key == "hospital") ctx.filters.setHospital(it.value);
        if (it.key == "insuredName") ctx.filters.setInsuredName(it.value);
        if (it.key == "doctorName") ctx.filters.setDoctorName(it.value);
        if (it.key == "from") ctx.filters.setFrom(it.value);
        if (it.key == "to") ctx.filters.setTo(it.value);
        if (it.key == "search") ctx.filters.setSearch(it.value);
        if (it.key == "getByClosedDate") {
          ctx.filters.setGetByClosedDate(it.value);
        }
        if (it.key == "reinvestigation") {
          ctx.filters.setGetReinvestigation(it.value);
        }
        if (it.key == "pushback") {
          ctx.filters.setPushback(it.value);
        }
      });
    }
  }, []);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header as="h4">Filter</Header>
        <Icon
          name="arrow alternate circle down outline"
          size="large"
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </div>
      {isExpanded && (
        <Grid columns={5}>
          <Grid.Row>
            <Grid.Column>
              <Form.Dropdown
                label="User Role"
                search
                selection
                placeholder="Select User Role"
                options={ctx.options.userRoles}
                value={ctx.filters.userRole}
                onChange={(_, { value }) =>
                  ctx.filters.setUserRole(value as string)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                label="User"
                search
                selection
                placeholder="Select User"
                options={ctx.options.user}
                value={ctx.filters.user}
                onChange={(_, { value }) =>
                  ctx.filters.setUser(value as string)
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Dropdown
                label="Case Category"
                search
                selection
                placeholder="Select Case Category"
                options={ctx.options.category}
                value={ctx.filters.category}
                onChange={(_, { value }) =>
                  ctx.filters.setCategory(value as string)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                label="Case Type"
                search
                selection
                placeholder="Select Case Type"
                options={ctx.options.caseType}
                value={ctx.filters.caseType}
                onChange={(_, { value }) =>
                  ctx.filters.setCaseType(value as string)
                }
              />
            </Grid.Column>
            
            {/*
                    <Grid.Column>
                        <Form.Dropdown
                            label='Case State'
                            search
                            selection
                            placeholder='Select Case State'
                            options={caseStateOptions}
                            value={ctx.filters.state}
                            onChange={(_, { value }) => ctx.filters.setState(value as string)} />
                    </Grid.Column>
                    */}
            <Grid.Column>
              <Form.Dropdown
                label="Client"
                search
                selection
                placeholder="Select Client"
                options={ctx.options.client}
                value={ctx.filters.client}
                onChange={(_, { value }) =>
                  ctx.filters.setClient(value as string)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                label="State"
                search
                selection
                placeholder="Select State"
                options={ctx.options.states}
                value={ctx.filters.states}
                onChange={(_, { value }) => {
                  ctx.filters.setStates(value as string)
                  clearDistrict()
                }
                }
              />
            </Grid.Column>

            <Grid.Column>
              <Form.Dropdown
                label="District"
                search
                selection
                placeholder="Select District"
                options={ctx.options.districts}
                value={ctx.filters.district}
                onChange={(_, { value }) =>
                  ctx.filters.setDistrict(value as string)
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Claim Number"
                placeholder="Enter Claim Number"
                value={claimNumber}
                onChange={(_, { value }) => setClaimNumber(value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode == 13) ctx.filters.setClaimNumber(claimNumber);
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                search
                selection
                label="Hospital"
                placeholder="Select Hospital"
                options={ctx.options.hospital}
                value={ctx.filters.hospital}
                onChange={(_, { value }) => ctx.filters.setHospital(value as string)}
                onKeyPress={(e: any) => ctx.filters.onChange(e as any)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Insured Name"
                placeholder="Enter Insured Name"
                value={insuredName}
                onChange={(_, { value }) => setInsuredName(value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode == 13) ctx.filters.setInsuredName(insuredName);
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Doctor Name"
                placeholder="Enter Doctor Name"
                value={doctorName}
                onChange={(_, { value }) => setDoctorName(value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode == 13) ctx.filters.setDoctorName(doctorName);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment compact>
                <Radio
                  toggle
                  label={"By Closed Date"}
                  checked={ctx.filters.getByClosedDate}
                  onChange={() => {
                    ctx.filters.setGetByClosedDate(
                      ctx.filters.getByClosedDate ? false : true
                    );
                  }}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Form.Input label="From Date">
                <DateTimePicker
                  placeholder="Select From Date"
                  type="date"
                  value={ctx.filters.from}
                  onChange={ctx.filters.setFrom}
                />
              </Form.Input>
            </Grid.Column>
            <Grid.Column>
              <Form.Input label="To Date">
                <DateTimePicker
                  placeholder="Select To Date"
                  type="date"
                  value={ctx.filters.to}
                  onChange={ctx.filters.setTo}
                />
              </Form.Input>
            </Grid.Column>
            <Grid.Column>
              {/*<Form.Input*/}
              {/*    label='Search'*/}
              {/*    placeholder='Enter Text...'*/}
              {/*    value={ctx.filters.search}*/}
              {/*    onChange={(_, { value }) => ctx.filters.setSearch(value)}*/}
              {/*/>*/}
              <Form.Input
                label="Search"
                placeholder="Enter Text..."
                value={searchText}
                onChange={(_, { value }) => setSearchText(value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode == 13) ctx.filters.setSearch(searchText);
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Segment compact>
                <Radio
                  toggle
                  label={"Reinvestigation"}
                  checked={ctx.filters.getReinvestigation}
                  onChange={() => {
                    ctx.filters.setGetReinvestigation(
                      !ctx.filters.getReinvestigation
                    );
                  }}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment compact>
                <Radio
                  toggle
                  label={"Pushback"}
                  checked={ctx.filters.getPushback}
                  onChange={() => {
                    ctx.filters.setPushback(
                      !ctx.filters.getPushback
                    );
                  }}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Button onClick={clearAllFilters}>Clear</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );

  function clearAllFilters() {
    ctx.filters.setUserRole("");
    ctx.filters.setUser("");
    ctx.filters.setCategory("");
    ctx.filters.setCaseType("");
    //  ctx.filters.setState('');
    ctx.filters.setClient("");

    ctx.filters.setStates("");
    ctx.filters.setDistrict("");
    setClaimNumber("");
    ctx.filters.setClaimNumber("");
    ctx.filters.setHospital("");
    setInsuredName("");
    ctx.filters.setInsuredName("");
    setDoctorName("");
    ctx.filters.setDoctorName("");
    ctx.filters.setFrom("");
    ctx.filters.setTo("");
    setSearchText("");
    ctx.filters.setSearch("");
    ctx.filters.setGetByClosedDate(false);
    ctx.filters.setGetReinvestigation(false);
    ctx.filters.setPushback(false);

    localStorage.removeItem("caseFilters");
  }

  function clearDistrict() {
    ctx.filters.setDistrict("");
  }
}

export default Filters;
