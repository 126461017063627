import React from "react"
import { Segment, Header, Form, Divider, Button, Icon, Label, Modal } from "semantic-ui-react"
import CaseRepository from "../../../../../../common/repository/CaseRepository"
import CompleteCase from "../../../../../../models/CompleteCase"
import Result from "../../../../../../common/repository/Result"
import { toast } from "../../../../../common/Toast"
import HospitalRepository from "../../../../../../common/repository/HospitalRepository"
import Medic from "../../../../../../models/Medic"
import RequirePermission from "../../../../../base/RequirePermission"
import permissions from "../../../../../../common/permissions"
import QCData from "../../../../../../models/QCData";
import InvestigationCard, { InvestigationCardInputValue } from "./InvestigationCard";
import AllocatedClaimAnalyst from "../../../../../../models/AllocatedClaimAnalyst";
import URLResource from "../../../../../../models/URLResource";
import ReactDOM from "react-dom";
import FilesPopup from "../../FilesPopup";
interface Props {
    model: CompleteCase
    updateModel?(model: CompleteCase): void
}

interface State {
    loading: boolean
    // insured_aadhaar: string
    // hospital_bedCount: string
    // hospital_icu: boolean
    // hospital_ot: boolean
    // hospital_indoorPharmacy: boolean
    // hospital_indoorLab: boolean
    // hospital_indoorXRay: boolean
    // hospital_indoorUSG: boolean
    // hospital_indoorMRI: boolean

    // doctor_value: string
    // doctor_name: string
    // doctor_qualification: string
    // doctor_registrationNumber: string
    // doctor_councilName: string
    // doctor_verified: boolean
    // doctor_list: Medic[]
    // doctor_options: DropdownItemProps[]

    // pathologist_value: string
    // pathologist_name: string
    // pathologist_qualification: string
    // pathologist_registrationNumber: string
    // pathologist_councilName: string
    // pathologist_labName: string
    // pathologist_verified: boolean
    // pathologist_list: Medic[]
    // pathologist_options: DropdownItemProps[]
    finalReportFile: File | null
    final_remarks: string
    major_observations: string
    data: QCData | null
    values: InvestigationCardInputValue[]
    repudiationEvidencePopupOpen: boolean
    Confirm_modalOpen: boolean
    Confirm_model: CompleteCase | null
}

class ActionClosure extends React.Component<Props, State> {
    private caseRepo = new CaseRepository()
    private hospitalRepo = new HospitalRepository()
    private portalRoot: HTMLElement | null = null

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            // insured_aadhaar: '',
            // hospital_bedCount: '',
            // hospital_icu: false,
            // hospital_ot: false,
            // hospital_indoorPharmacy: false,
            // hospital_indoorLab: false,
            // hospital_indoorXRay: false,
            // hospital_indoorUSG: false,
            // hospital_indoorMRI: false,
            // doctor_value: '',
            // doctor_name: '',
            // doctor_qualification: '',
            // doctor_registrationNumber: '',
            // doctor_councilName: '',
            // doctor_verified: false,
            // doctor_list: [],
            // doctor_options: [],
            // pathologist_value: '',
            // pathologist_name: '',
            // pathologist_qualification: '',
            // pathologist_registrationNumber: '',
            // pathologist_councilName: '',
            // pathologist_labName: '',
            // pathologist_verified: false,
            // pathologist_list: [],
            // pathologist_options: [],
            finalReportFile: null,
            final_remarks: '',
            major_observations: '',
            data: null,
            values: [],
            repudiationEvidencePopupOpen: false,

            Confirm_modalOpen: false,
            Confirm_model: null
        }
    }

    componentDidMount() {
        this.getData()
        this.portalRoot = document.getElementsByTagName('body')[0]
    }

    getData = () => {
        this.setState({ loading: true }, async () => {
            const { model } = this.props
            const result = await this.caseRepo.getQCData({ case_id: model.getId() })

            if (result instanceof Result.Success) {
                const data = result.data
                const values = data.getAllocations().map((it, i) => this.mapAllocatedClaimAnalystToValue(`${i}`, it))
                this.setState({ loading: false, values, data: data })
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                })
            }
        })
    }


    mapAllocatedClaimAnalystToValue = (fakeId: string, allocatedClaimAnalyst: AllocatedClaimAnalyst) => {
        const value = { ...InvestigationCard.initialValue }

        value.id = fakeId
        value.claimAnalystId = allocatedClaimAnalyst.getClaimAnalyst().getId()
        value.investigators = allocatedClaimAnalyst.getAllocatedInvestigators().map(it => it.getInvestigator().getId())
        return value
    }

    /*
    mapAllocatedClaimAnalystToValue = (fakeId: string, allocatedClaimAnalyst: AllocatedClaimAnalyst) => {
        const value = { ...InvestigationCard.initialValue }

        value.id = fakeId
        value.claimAnalystId = allocatedClaimAnalyst.getClaimAnalyst().getId()
        const investigators = allocatedClaimAnalyst.getAllocatedInvestigators().map(it => {
            const id = it.getInvestigator().getId()
            const allocationId = it.getId()
            const casePortion = it.getCasePortion()
            const investigationOutcome = it.getOutcome()
            const investigationLocation = it.getInvestigationLocation();
            const allowance = it.getAllowance();
            const documentExpense = it.getDocumentExpense();
            const extraExpense = it.getExtraExpense();
            const approvedExpense = it.getApprovedExpense();

            let capping = ''
            if (casePortion !== null)
                capping = casePortion.toString()

            let outcome = ''
            if (investigationOutcome !== null)
                outcome = investigationOutcome
            return { id, allocationId, capping, outcome, investigationLocation, allowance, documentExpense, extraExpense, approvedExpense }
        })

        value.investigators = investigators
        return value
    }
    */

    onSubmit = () => {
        this.setState({ loading: true }, async () => {
            const { model, updateModel } = this.props
            const result = await this.caseRepo.closeCase({
                case_id: model.getId(),
                final_report: this.state.finalReportFile!,
                final_remarks: this.state.final_remarks,
                major_observations: this.state.major_observations
                // insured_aadhaar_card: this.state.insured_aadhaar,
                // bed_count: this.state.hospital_bedCount,
                // icu: this.state.hospital_icu,
                // ot: this.state.hospital_ot,
                // indoor_pharmacy: this.state.hospital_indoorPharmacy,
                // indoor_lab: this.state.hospital_indoorLab,
                // indoor_xray: this.state.hospital_indoorXRay,
                // indoor_usg: this.state.hospital_indoorUSG,
                // indoor_mri: this.state.hospital_indoorMRI,
                // doctor: {
                //     name: this.state.doctor_name,
                //     qualification: this.state.doctor_qualification,
                //     registration_number: this.state.doctor_registrationNumber,
                //     council_name: this.state.doctor_councilName,
                //     verified: this.state.doctor_verified,
                // },
                // pathologist: {
                //     name: this.state.pathologist_name,
                //     qualification: this.state.pathologist_qualification,
                //     registration_number: this.state.pathologist_registrationNumber,
                //     council_name: this.state.pathologist_councilName,
                //     lab_name: this.state.pathologist_labName,
                //     verified: this.state.pathologist_verified
                // }
            })

            if (result instanceof Result.Success) {
                this.setState({ loading: false, Confirm_modalOpen: false }, () => {
                    const c = result.data as CompleteCase
                    toast.success('Successfully closed case')
                    if (updateModel)
                        updateModel(c)
                })
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong'
                    toast.error(message)
                })
            }
        })
    }


    disabledSubmitButton = () => {
        return (
            // this.state.loading ||
            // this.state.insured_aadhaar.trim().length === 0 ||
            // this.state.hospital_bedCount.trim().length === 0 ||
            // this.state.doctor_name.trim().length === 0 ||
            // this.state.doctor_qualification.trim().length === 0 ||
            // this.state.doctor_registrationNumber.trim().length === 0 ||
            // this.state.doctor_councilName.trim().length === 0 ||
            // this.state.pathologist_name.trim().length === 0 ||
            // this.state.pathologist_qualification.trim().length === 0 ||
            // this.state.pathologist_registrationNumber.trim().length === 0 ||
            // this.state.pathologist_councilName.trim().length === 0 ||
            // this.state.pathologist_labName.trim().length === 0 ||
            this.state.final_remarks.trim().length === 0 ||
            this.state.finalReportFile === null
        )
    }


    // onDoctorNameChange = async (value: string) => {
    //     const result = await this.hospitalRepo.getDoctors({ name: value })
    //     if (result instanceof Result.Success) {
    //         const doctors = result.data.items
    //         const options = doctors.map(this.mapMedicToOption)
    //         this.setState({ doctor_options: options, doctor_list: doctors })
    //     }
    // }


    // onPathologistNameChange = async (value: string) => {
    //     const result = await this.hospitalRepo.getPathologists({ name: value })
    //     if (result instanceof Result.Success) {
    //         const pathologists = result.data.items
    //         const options = pathologists.map(this.mapMedicToOption)
    //         this.setState({ pathologist_options: options, pathologist_list: pathologists })
    //     }
    // }


    mapMedicToOption = (medic: Medic) => {
        const content = (
            <div style={{ margin: '0 4px' }}>
                <div>{medic.getName()}</div>
                <div style={{ fontSize: 12 }}>{medic.getRegistrationNumber()}</div>
            </div>
        )
        return { text: medic.getName(), value: medic.getId(), key: medic.getId(), content }
    }


    onChange = (value: InvestigationCardInputValue) => {
        const values = this.state.values
        const i = values.findIndex(it => it.id === value.id);
        if (i === -1)
            return
        values[i] = value;
        this.setState({ values: [...values] })
    }
    /*
    updateInvestigatorsInfo = () => {
        this.setState({ loading: true }, async () => {
            const { model, updateModel } = this.props;
            const ro: PerformQualityCheckRequestObject = {
                case_id: model.getId(),
                allocations: this.state.values.map(it => {
                    return {
                        head_id: it.claimAnalystId,
                        team: it.investigators.map(inv => ({
                            allocation_id: inv.allocationId,
                            investigator_id: inv.id,
                            case_portion: inv.capping,
                            outcome: inv.outcome,
                            allowance: inv.allowance,
                            investigation_location : {state: inv.investigationLocation?.getState(), district: inv.investigationLocation?.getDistrict()},
                            document_expense: inv.documentExpense,
                            extra_expense: inv.extraExpense,
                            approved_expense: inv.approvedExpense

                        })),
                        grade_report: {
                            allocation_hygiene: it.allocationHygiene,
                            report_quality: it.reportQuality,
                            outcome: it.outcome,
                            tat: it.tat
                        },
                        feedback: it.feedback
                    }
                })
            }

            const result = await this.caseRepo.updateQualityCheck(ro)
            if (result instanceof Result.Success) {
                this.setState({ loading: false }, () => {
                    const c = result.data as CompleteCase;
                    toast.success('Successfully updated');
                    if (updateModel)
                        updateModel(c);
                })
            } else {
                this.setState({ loading: false }, () => {
                    const message = result.message || 'Something went wrong';
                    toast.error(message);
                })
            }
        })
    }
    */
    render() {

        const { model } = this.props

        let allocations = null
        if (this.state.data !== null) {
            allocations = this.state.data.getAllocations().map((it, i) => {
                return (
                    <InvestigationCard
                        model={it}
                        value={this.state.values.find(v => v.id === `${i}`)!}
                        onChange={this.onChange}
                        newData={model}
                    />
                )
            })
        }

        let report = null
        if (this.state.data !== null)
            report = this.state.data.getReport()

        let repudiationEvidence: URLResource[] = []
        if (this.state.data !== null)
            repudiationEvidence = this.state.data.getRepudiationEvidences()

        let queryTriggerDocument: any;
        if (this.state.data !== null)
            queryTriggerDocument = this.state.data.getQueryTriggerDocument();

        let ReinvestigationReport = null;
        let ReinvestigationFilalReports = null;
        let ReinvestigationRepudiationEvidence: any[] = [];
        let ReinvestigationQueryTriggerDocument = null;

        if (this.props.model.getReinvestigation()) {
            ReinvestigationReport = this.props.model.getReport();
            ReinvestigationFilalReports = this.props.model.getFinalReport()

            console.log(model.getQueryTriggerDocument(), "updsauyrer")
            if (model.getRepudiationEvidence())
                ReinvestigationRepudiationEvidence = this.props.model.getRepudiationEvidence();

            if (this.props.model.getQueryTriggerDocument() !== null) ReinvestigationQueryTriggerDocument = this.props.model.getQueryTriggerDocument();
        }


        return (
            <>
                <Segment basic>

                    {this.props.model.getReinvestigation() && <div>
                        <Header as="h3">
                            Re-investigation Reports
                        </Header>
                        {/* report */}
                        <Button
                            as="a"
                            href={ReinvestigationReport && ReinvestigationReport.getURL()}
                            disabled={ReinvestigationReport === null}
                            target="_blank"
                            icon
                            primary
                            labelPosition="left"
                            size="tiny"
                        >
                            <Icon name="file alternate outline" />
                            View Report
                        </Button>
                        {/* report */}

                        {/* repudiation evidence */}
                        {model.getOutcome() == "Repudiation" && <Button
                            icon
                            primary
                            labelPosition="left"
                            size="tiny"
                            disabled={
                                this.state.repudiationEvidencePopupOpen ||
                                ReinvestigationRepudiationEvidence.length === 0
                            }
                            onClick={() =>
                                this.setState({ repudiationEvidencePopupOpen: true })
                            }
                        >
                            <Icon name="file alternate outline" />
                            {ReinvestigationRepudiationEvidence.length === 0
                                ? "No Repudiation Evidence"
                                : "View Repudiation Evidence"}
                        </Button>}
                        {/* repudiation evidence */}
                        {/* <Label basic size={"large"} color={"blue"}>
            Case Type{" "}
            <Label.Detail>{model.getType().getTitle()}</Label.Detail>
          </Label>
          <Label basic size={"large"} color={"blue"}>
            Case Outcome <Label.Detail>{model.getOutcome()}</Label.Detail>
          </Label> */}
                        {model.getRepudiationGround() && model.getOutcome() == "Repudiation" && <Label basic size={"large"} color={"blue"}>
                            Repudation Ground <Label.Detail>{model.getRepudiationGround()}</Label.Detail>
                        </Label>}
                        {/* Final Report */}
                        {
                            ReinvestigationFilalReports?.getURL() !== null &&
                            <Button
                                as="a"
                                href={ReinvestigationFilalReports && ReinvestigationFilalReports.getURL()}
                                disabled={ReinvestigationFilalReports === null}
                                target="_blank"
                                icon
                                primary
                                labelPosition="left"
                                size="tiny"
                            >
                                <Icon name="file alternate outline" />
                                Final Report
                            </Button>}
                        {/* Repudiation Evidence */}
                        {this.state.repudiationEvidencePopupOpen &&
                            ReactDOM.createPortal(
                                <FilesPopup
                                    title="Repudiation Evidence"
                                    onClose={() => this.setState({ repudiationEvidencePopupOpen: false })}

                                    files={repudiationEvidence} allocationId={model.getId()} />,
                                this.portalRoot!
                            )}

                        {/* Query triger Document */}
                        {ReinvestigationQueryTriggerDocument?.getURL() !== null && model.getOutcome() == "Inconclusive" && <Button
                            as="a"
                            href={ReinvestigationQueryTriggerDocument && ReinvestigationQueryTriggerDocument.getURL()}
                            disabled={ReinvestigationQueryTriggerDocument === null}
                            target="_blank"
                            icon
                            primary
                            labelPosition="left"
                            size="tiny"
                        >
                            <Icon name="file alternate outline" />
                            Query Trigger Document
                        </Button>}
                    </div>
                    }
                    {/* report */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        margin: "30px 0px"
                    }}>
                        <div>
                            {/* report */}
                            <Header as="h3">
                                Reports
                            </Header>
                            <Button
                                as='a'
                                href={report && report.getURL()}
                                disabled={report === null}
                                target='_blank'
                                icon
                                primary
                                labelPosition='left'
                                size='tiny'>
                                <Icon name='file alternate outline' />
                                View Report
                            </Button>
                            {/* report */}

                            {/* repudiation evidence */}
                            <Button
                                icon
                                primary
                                labelPosition='left'
                                size='tiny'
                                disabled={
                                    this.state.repudiationEvidencePopupOpen ||
                                    repudiationEvidence.length === 0
                                }
                                onClick={() => this.setState({ repudiationEvidencePopupOpen: true })}>
                                <Icon name='file alternate outline' />
                                {repudiationEvidence.length === 0 ? 'No Repudiation Evidence' : 'View Repudiation Evidence'}
                            </Button>
                            {/* repudiation evidence */}
                            <Label basic size={'large'} color={'blue'}>
                                Case Type <Label.Detail>{model.getType().getTitle()}</Label.Detail>
                            </Label>
                            <Label basic size={'large'} color={'blue'}>
                                Case Outcome <Label.Detail>{model.getOutcome()}</Label.Detail>
                            </Label>
                            {/* repudiation_ground */}
                            {model.getOutcome() === "Repudiation" && <Label basic size={'large'} color={'blue'}>
                                Repudiation Ground <Label.Detail>{model.getRepudiationGround()}</Label.Detail>
                            </Label>}

                            {queryTriggerDocument?.getURL() !== null && <Button
                                as='a'
                                href={queryTriggerDocument && queryTriggerDocument.getURL()}
                                disabled={queryTriggerDocument === null}
                                target='_blank'
                                icon
                                primary
                                labelPosition='left'
                                size='tiny'>
                                <Icon name='file alternate outline' />
                                Query Trigger Document
                            </Button>}

                            {this.state.repudiationEvidencePopupOpen && (
                                ReactDOM.createPortal(
                                    <FilesPopup
                                        title='Repudiation Evidence'
                                        onClose={() => this.setState({ repudiationEvidencePopupOpen: false })}
                                        files={repudiationEvidence} allocationId={model.getId()} />,
                                    this.portalRoot!
                                )
                            )}

                        </div>
                    </div>
                    {allocations}
                    {/*<Segment>*/}
                    {/*<Button*/}
                    {/*    icon*/}
                    {/*    labelPosition='left'*/}
                    {/*    primary*/}
                    {/*    onClick={this.updateInvestigatorsInfo}>*/}
                    {/*    <Icon name='check circle' />*/}
                    {/*    Update Information*/}
                    {/*</Button>*/}
                    {/*</Segment>*/}

                    <Divider />
                    <Header as="h3">Case Closure</Header>

                    <Divider hidden />

                    <Form loading={this.state.loading}>
                        {/* <Header as="h5">Insured Information</Header>
                    <Divider /> */}

                        {/* <Segment>
                        <Form.Group inline>
                            <FormLabel width="100px">Aadhaar Number</FormLabel>
                            <Input
                                placeholder='Enter Aadhaar Card Number'
                                value={this.state.insured_aadhaar}
                                onChange={(_, { value }) => this.setState({ insured_aadhaar: value })} />
                        </Form.Group>
                    </Segment>


                    <Header as="h5">Hospital Information</Header>
                    <Divider />

                    <Segment.Group>
                        <Segment>
                            <Form.Group inline>
                                <FormLabel width="100px">Number of Beds</FormLabel>
                                <Input
                                    placeholder='e.g. 100'
                                    value={this.state.hospital_bedCount}
                                    onChange={(_, { value }) => this.setState({ hospital_bedCount: value })} />
                            </Form.Group>
                        </Segment>

                        <Segment>
                            <Grid columns={4}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox
                                            label="ICU Available"
                                            checked={this.state.hospital_icu}
                                            onChange={() => this.setState(s => ({ hospital_icu: !s.hospital_icu }))}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Checkbox
                                            label="OT Available"
                                            checked={this.state.hospital_ot}
                                            onChange={() => this.setState(s => ({ hospital_ot: !s.hospital_ot }))} />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Checkbox
                                            label="Indoor Pharmacy"
                                            checked={this.state.hospital_indoorPharmacy}
                                            onChange={() => this.setState(s => ({ hospital_indoorPharmacy: !s.hospital_indoorPharmacy }))} />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Checkbox
                                            label="Indoor Lab"
                                            checked={this.state.hospital_indoorLab}
                                            onChange={() => this.setState(s => ({ hospital_indoorLab: !s.hospital_indoorLab }))} />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Checkbox
                                            label="Indoor X-Ray"
                                            checked={this.state.hospital_indoorXRay}
                                            onChange={() => this.setState(s => ({ hospital_indoorXRay: !s.hospital_indoorXRay }))} />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Checkbox
                                            label="Indoor USG"
                                            checked={this.state.hospital_indoorUSG}
                                            onChange={() => this.setState(s => ({ hospital_indoorUSG: !s.hospital_indoorUSG }))} />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Checkbox
                                            label="Indoor MRI"
                                            checked={this.state.hospital_indoorMRI}
                                            onChange={() => this.setState(s => ({ hospital_indoorMRI: !s.hospital_indoorMRI }))} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Segment.Group>

                    <Divider hidden />
                    <Header as="h5">Doctor Information</Header>
                    <Divider />

                    <Segment.Group>
                        <Segment>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Group inline>
                                            <FormLabel width="120px">Doctor Name</FormLabel>
                                            <Dropdown
                                                search
                                                selection
                                                options={this.state.doctor_options}
                                                allowAdditions
                                                value={this.state.doctor_value}
                                                onAddItem={(_, { value }) => {
                                                    const v = value as string
                                                    const options = [{ text: v, value: v, key: v }, ...this.state.doctor_options]
                                                    this.setState({ doctor_options: options })
                                                }}
                                                onChange={(_, { value }) => {
                                                    const id = value as string
                                                    const doctor = this.state.doctor_list.find(it => it.getId() === id)
                                                    if (doctor !== undefined) {
                                                        this.setState({
                                                            doctor_value: doctor.getId(),
                                                            doctor_name: doctor.getName(),
                                                            doctor_councilName: doctor.getCouncilName(),
                                                            doctor_qualification: doctor.getQualification(),
                                                            doctor_registrationNumber: doctor.getRegistrationNumber(),
                                                        })
                                                    } else {
                                                        this.setState({
                                                            doctor_name: id,
                                                            doctor_value: id,
                                                            doctor_councilName: '',
                                                            doctor_qualification: '',
                                                            doctor_registrationNumber: '',
                                                            doctor_verified: false,
                                                        })
                                                    }
                                                }}
                                                onSearchChange={(_, { value }) => this.onDoctorNameChange(value as string)} />
                                        </Form.Group>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Form.Group inline>
                                            <FormLabel width="120px">Qualification</FormLabel>
                                            <Input
                                                value={this.state.doctor_qualification}
                                                onChange={(_, { value }) => this.setState({ doctor_qualification: value })} />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Group inline>
                                            <FormLabel width="120px">Registration Number</FormLabel>
                                            <Input
                                                value={this.state.doctor_registrationNumber}
                                                onChange={(_, { value }) => this.setState({ doctor_registrationNumber: value })} />
                                        </Form.Group>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Form.Group inline>
                                            <FormLabel width="120px">Council Name</FormLabel>
                                            <Input
                                                value={this.state.doctor_councilName}
                                                onChange={(_, { value }) => this.setState({ doctor_councilName: value })} />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>

                        <Segment>
                            <Checkbox
                                label="Verified"
                                checked={this.state.doctor_verified}
                                onChange={() => this.setState(s => ({ doctor_verified: !s.doctor_verified }))} />
                        </Segment>
                    </Segment.Group>

                    <Divider hidden />
                    <Header as="h5">Pathologist Information</Header>
                    <Divider />

                    <Segment.Group>
                        <Segment>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Group inline>
                                            <FormLabel width="120px">Pathologist Name</FormLabel>
                                            <Dropdown
                                                search
                                                selection
                                                options={this.state.pathologist_options}
                                                allowAdditions
                                                value={this.state.pathologist_value}
                                                onAddItem={(_, { value }) => {
                                                    const v = value as string
                                                    const options = [{ text: v, value: v, key: v }, ...this.state.pathologist_options]
                                                    this.setState({ pathologist_options: options })
                                                }}
                                                onChange={(_, { value }) => {
                                                    const id = value as string
                                                    const pathologist = this.state.pathologist_list.find(it => it.getId() === id)
                                                    if (pathologist !== undefined) {
                                                        this.setState({
                                                            pathologist_value: pathologist.getId(),
                                                            pathologist_name: pathologist.getName(),
                                                            pathologist_councilName: pathologist.getCouncilName(),
                                                            pathologist_labName: pathologist.getLabName() || '',
                                                            pathologist_qualification: pathologist.getQualification(),
                                                            pathologist_registrationNumber: pathologist.getRegistrationNumber(),
                                                        })
                                                    } else {
                                                        this.setState({
                                                            pathologist_name: id,
                                                            pathologist_value: id,
                                                            pathologist_councilName: '',
                                                            pathologist_labName: '',
                                                            pathologist_qualification: '',
                                                            pathologist_registrationNumber: '',
                                                            pathologist_verified: false,
                                                        })
                                                    }
                                                }}
                                                onSearchChange={(_, { value }) => this.onPathologistNameChange(value as string)} />

                                        </Form.Group>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Form.Group inline>
                                            <FormLabel width="120px">Qualification</FormLabel>
                                            <Input
                                                value={this.state.pathologist_qualification}
                                                onChange={(_, { value }) => this.setState({ pathologist_qualification: value })} />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Group inline>
                                            <FormLabel width="120px">Registration Number</FormLabel>
                                            <Input
                                                value={this.state.pathologist_registrationNumber}
                                                onChange={(_, { value }) => this.setState({ pathologist_registrationNumber: value })} />
                                        </Form.Group>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Form.Group inline>
                                            <FormLabel width="120px">Council Name</FormLabel>
                                            <Input
                                                value={this.state.pathologist_councilName}
                                                onChange={(_, { value }) => this.setState({ pathologist_councilName: value })} />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Group inline>
                                            <FormLabel width='120px'>Lab Name</FormLabel>
                                            <Input
                                                value={this.state.pathologist_labName}
                                                onChange={(_, { value }) => this.setState({ pathologist_labName: value })} />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>

                        <Segment>
                            <Checkbox
                                label="Verified"
                                checked={this.state.pathologist_verified}
                                onChange={() => this.setState(s => ({ pathologist_verified: !s.pathologist_verified }))} />
                        </Segment>
                    </Segment.Group> */}
                        {/* <Form.TextArea
                    label="Major Observation"
                    type="text"
                    value={this.state.major_observations}
                    onChange={(_, { value }) => {
                        const inputValue = value?.toString() || ''; // Ensure the value is treated as a string
                        if (inputValue.length > 2500) {
                          toast.error("Text length cannot exceed 2500 characters.")
                          
                        } else {
                            this.setState({ major_observations: inputValue });
                        }
                    }}
                /> */}
                        {/* <Form.TextArea
    label="Major Observation"
    type="text"
    value={this.state.major_observations}
    onChange={(_, { value }) => {
        const inputValue = value?.toString() || ''; // Ensure the value is treated as a string
        if (inputValue.length > 2500) {
            toast.error("Text length cannot exceed 2500 characters.");
        } else {
            this.setState({ major_observations: inputValue });
        }
    }}
    onKeyDown={(e:any) => e.preventDefault()} // Prevent manual typing
/> */}
                        <Form.TextArea
                            label="Major Observation"
                            type="text"
                            value={this.state.major_observations}
                            onChange={(_, { value }) => {
                                const inputValue = value?.toString() || ''; // Ensure value is a string
                                if (inputValue.length > 2500) {
                                    toast.error("Text length cannot exceed 2500 characters.");
                                } else {
                                    this.setState({ major_observations: inputValue });
                                }
                            }}
                            onKeyDown={(e: any) => {
                                if (!e.ctrlKey && !e.metaKey) {
                                    e.preventDefault(); // Block manual typing but allow copy-paste (Ctrl + V / Cmd + V)
                                }
                            }}
                        />
                        <Button
                            onClick={() => this.setState({ major_observations: "" })}

                            style={{ marginTop: "2px", marginBottom: "10px" }}
                        > Clear Observation</Button>

                        {/* 
                       <Form.Input
                            label='Final Remarks'
                            type='text'
                            value={this.state.final_remarks}
                            onChange={(_, { value }) => this.setState({ final_remarks: value })}
                        /> */}
                        <Form.Input
                            label="Final Remarks"
                            type="text"
                            value={this.state.final_remarks}
                            onChange={(_, { value }) => this.setState({ final_remarks: value })}
                            onKeyDown={(e: any) => {
                                if (!e.ctrlKey && !e.metaKey) {
                                    e.preventDefault();
                                }
                            }}
                        />
                          <Button
                            onClick={() => this.setState({ final_remarks: "" })}

                            style={{ marginTop: "2px", marginBottom: "10px" }}
                        > Clear Remarks</Button>
                        <Form.Input
                            label='Clouser Evidence'
                            type='file'
                            onChange={(e) => {
                                const files = e.target.files
                                if (files === null) {
                                    this.setState({ finalReportFile: null })
                                } else {
                                    this.setState({ finalReportFile: files[0] })
                                }
                            }} />
                        <Button
                            positive
                            disabled={this.disabledSubmitButton()}
                            onClick={() => this.setState({ Confirm_modalOpen: true })}>
                            Submit
                        </Button>
                    </Form>

                    <Modal open={this.state.Confirm_modalOpen} onClose={() => this.setState({ Confirm_modalOpen: false, Confirm_model: null })} size="mini" closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                        <Modal.Header>Confirmation</Modal.Header>
                        <Modal.Content>
                            <Form loading={this.state.loading}>
                                <p>Are you sure you want to Submit this?</p>
                                <Button color="green" inverted onClick={this.onSubmit}>
                                    Submit
                                </Button>
                                <Button onClick={() => this.setState({ Confirm_modalOpen: false })} style={{ marginLeft: 4 }}>
                                    Cancel
                                </Button>
                            </Form>
                        </Modal.Content>
                    </Modal>
                </Segment>
            </>

        );
    }
}

export default (props: any) => {
    return (
        <RequirePermission permission={permissions.Case.close}>
            <ActionClosure {...props} />
        </RequirePermission>
    )
}
