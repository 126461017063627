export default {
  apiBaseURLSocket: "https://verifacto.amable.in",
  mrdRecipt: 'https://verifacto.amable.in/',
  apiBaseURL: "https://verifacto.amable.in/api" ,
}
//   apiBaseURLSocket: "http://192.168.0.133:3111",
//   mrdRecipt: 'http://192.168.12.70:3014/',
//   apiBaseURL: "http://192.168.0.144:3111/api" ,
// }

