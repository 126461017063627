import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Page from '../../../layout/Page'
import Table from '../../../app/Table'
import CaseRepository, { GetCasesRequestObject } from '../../../../common/repository/CaseRepository'
import Result from '../../../../common/repository/Result'
import Case from '../../../../models/Case'
import CaseColumnRenderer from './CaseColumnRenderer'
import RequirePermission from '../../../base/RequirePermission'
import permissions from '../../../../common/permissions'
import { toast } from '../../../common/Toast'
import Filters from './Filters'
import { Segment, DropdownItemProps, Button, Icon } from 'semantic-ui-react'
import CaseListPageContext from './CaseListPageContext'
import ClientRepository from '../../../../common/repository/ClientRepository'
import RoleRepository from '../../../../common/repository/RoleRespository'
import CaseCategoryRepository from '../../../../common/repository/CaseCategoryRepository'
import StateDistrictRepository from '../../../../common/repository/StateDistrictRepository'
import UserRepository from '../../../../common/repository/UserRepository'
import HospitalRepository from '../../../../common/repository/HospitalRepository'
import CaseTypeRepository from '../../../../common/repository/CaseTypeRepository'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../../../redux'
import constants from '../../../../common/constants'
import LocalStorage from '../../../../lib/LocalStorage'
import axios from 'axios'
import config from '../../../../common/repository/config'
import StatesDistrictsRepository from '../../../../common/repository/StatesDistrictsRepository'

type Role = 'admin' | 'ceo' | 'md' | 'investigator' | 'op' |'tl' |'ClaimAnalyst'|'mi'

function CaseListPage() {
  const authState = useSelector((state: AppState) => state.auth)
  const dispatch = useDispatch()
  const user = authState.user

  const clientRepo = new ClientRepository()
  const roleRepo = new RoleRepository()
  const caseCategoryRepo = new CaseCategoryRepository()
  const stateRepo = new StateDistrictRepository()
  const userRepo = new UserRepository()
  const caseRepo = new CaseRepository()
  const hospitalRepo = new HospitalRepository()
  const caseTypeRepo = new CaseTypeRepository()
  const StateSDisterictRepo = new StatesDistrictsRepository()

  const location = useLocation<Filters>()
  const filters = location.state

  const [isLoading, setIsLoading] = useState(false)
  const [limit, setLimit] = useState(Table.ROWS_PER_PAGE[0])
  const [offset, setOffset] = useState(0)
  const [roleOptions, setRoleOptions] = useState<DropdownItemProps[]>([])
  const [userOptions, setUserOptions] = useState<DropdownItemProps[]>([])
  const [caseCategoryOptions, setCaseCategoryOptions] = useState<DropdownItemProps[]>([])
  const [caseTypeOptions, setCaseTypeOptions] = useState<DropdownItemProps[]>([])
  const [clientOptions, setClientOptions] = useState<DropdownItemProps[]>([])

  const [stateOptions, setStateOptions] = useState<DropdownItemProps[]>([]);

  const [districtOptions, setDistrictOptions] = useState<DropdownItemProps[]>([])
  const [hospitalOptions, setHospitalOptions] = useState<DropdownItemProps[]>([])
  const [loadingEssentialData, setLoadingEssentialData] = useState(true)
  const [searchFilter, setSearchFilter] = useState((filters && filters.search) || '')
  const [userRoleFilter, setUserRoleFilter] = useState((filters && filters.roleTitle) || '')
  const [userFilter, setUserFilter] = useState((filters && filters.userId) || '')
  const [caseCategoryFilter, setCaseCategoryFilter] = useState((filters && filters.caseCategoryCode) || '')
  const [caseStateFilter, setCaseStateFilter] = useState((filters && filters.caseState) || '')
  const [clientFilter, setClientFilter] = useState((filters && filters.clientId) || '')

  const [statesFilter, setStatesFilter] = useState((filters && filters.states) || '');

  const [districtFilter, setDistrictFilter] = useState((filters && filters.district) || '')
  const [claimNumberFilter, setClaimNumberFilter] = useState((filters && filters.claimNumber) || '')
  const [doctorNameFilter, setDoctorNameFilter] = useState((filters && filters.doctorName) || '')
  const [hospitalFilter, setHospitalFilter] = useState((filters && filters.hospitalId) || '')
  const [hospitalSearch, setHospitalSearch] = useState("")
  const [caseTypeFilter, setCaseTypeFilter] = useState((filters && filters.caseTypeId) || '')
  const [insuredNameFilter, setInsuredNameFilter] = useState((filters && filters.insuredName) || '')
  const [fromFilter, setFromFilter] = useState((filters && filters.from) || '')
  const [toFilter, setToFilter] = useState((filters && filters.to) || '')
  const [cases, setCases] = useState<Case[]>([])
  const [totalCaseCount, setTotalCaseCount] = useState(0)
  const [getByClosedDate, setGetByClosedDate] = useState(false)
  const [getReinvestigation, setGetReinvestigation] = useState((filters && filters.reinvestigation) || false);
  const [getPushback, setPushback] = useState((filters && filters.pushback) || false);
  const [reporteeOption,setReporteeOption]=useState(filters && filters.reportee);
  const [caseIdsForExcel, setCaseIdsForExcel] = useState<string[]>([])
  //
  // const [pageTitle, setPageTitle] = useState('');
  let caseColumnRenderer: CaseColumnRenderer
  if (caseStateFilter == constants.caseStates.close || caseStateFilter == constants.caseStates.graded) caseColumnRenderer = new CaseColumnRenderer(true)
  else caseColumnRenderer = new CaseColumnRenderer()

  let pageTitle =
    caseStateFilter == constants.caseStates.initiated
      ? 'Initiated'
      : caseStateFilter == constants.caseStates.generated
        ? 'Generated'
        : caseStateFilter == constants.caseStates.open
          ? 'Open'
          : caseStateFilter == constants.caseStates.reinvestigate
            ? "Re-Investigate"
            : caseStateFilter == constants.caseStates.fold
              ? 'Pending QC'
              : caseStateFilter == constants.caseStates.checked
                ? 'Pending closure'
                : caseStateFilter == constants.caseStates.close
                  ? 'Pending Grading'
                  : caseStateFilter == constants.caseStates.graded
                    ? 'Closed'
                    : ''


  // caseStateFilter == constants.caseStates.reinvestigate
  //   ? "Re-Investigate"
  //   : ""


  pageTitle = pageTitle + ' Cases'

  useEffect(() => {
    effectLoadEssentialData()
  }, [loadingEssentialData])
  useEffect(effectTriggerLoadCases, [
    userFilter,
    caseCategoryFilter,
    caseTypeFilter,
    caseStateFilter,
    clientFilter,
    statesFilter,

    districtFilter,
    claimNumberFilter,
    hospitalFilter,
    insuredNameFilter,
    doctorNameFilter,
    fromFilter,
    toFilter,
    searchFilter,
    getByClosedDate,
    getReinvestigation,
    getPushback,
  ])
  useEffect(() => {
    effectLoadCases()
  }, [isLoading])
  useEffect(() => {
    effectLoadUsers()
  }, [userRoleFilter])
  useEffect(() => {
    effectLoadDistrict()
  }, [statesFilter])
  
  useEffect(
    () =>
      cases &&
      (() => {
        let CaseIdsTmp: string[] = []
        CaseIdsTmp = cases.map((it) => it.getId().toString())
        setCaseIdsForExcel(CaseIdsTmp)
      })(),
    [cases]
  )

  const onRowsPerPageChange = (page: number, rowsPerPage: number) => {
    const offset = (page - 1) * rowsPerPage,
      limit = offset + rowsPerPage
    setLimit(limit)
    setOffset(offset)
    setIsLoading(true)
    effectLoadCases()
  }

  const downloadData = async () => {
    let rs = createRequestObject()
    rs.limit = undefined
    rs.offset = undefined
    if (!rs) rs = {}
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN)
    try {
      if (Object.entries(rs).length > 0) {
        toast.success('Download will start shortly!')
        let reqData = axios({
          url: config.apiBaseURL + `/case/export-excel`,
          method: 'POST',
          responseType: 'blob',
          data: rs,
          headers: { ['x-access-token']: token },
        })
        reqData
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'casesData.xlsx')
            document.body.appendChild(link)
            link.click()
            link.remove()
          })
          .catch((e) => {
            toast.error('Something went wrong while downloading data!')
          })
      } else {
        toast.error('download feature not available yet!.')
      }
    } catch (e) {
      toast.error('Something went wrong while downloading')
    }
  }

  return (
    <CaseListPageContext.Provider
      value={{
        options: {
          category: caseCategoryOptions,
          caseType: caseTypeOptions,
          client: clientOptions,

          states: stateOptions,

          districts: districtOptions,
          user: userOptions,
          userRoles: roleOptions,
          hospital: hospitalOptions,
        },
        filters: {
          category: caseCategoryFilter,
          reportee:reporteeOption,
          setReportee:setReporteeOption,
          setCategory: setCaseCategoryFilter,
          caseType: caseTypeFilter,
          setCaseType: setCaseTypeFilter,
          state: caseStateFilter,
          setState: setCaseStateFilter,
          client: clientFilter,
          setClient: setClientFilter,

          states: statesFilter,
          setStates: setStatesFilter,

          district: districtFilter,
          setDistrict: setDistrictFilter,
          user: userFilter,
          setUser: setUserFilter,
          userRole: userRoleFilter,
          setUserRole: setUserRoleFilter,
          search: searchFilter,
          setSearch: setSearchFilter,
          claimNumber: claimNumberFilter,
          setClaimNumber: setClaimNumberFilter,
          doctorName: doctorNameFilter,
          setDoctorName: setDoctorNameFilter,
          hospital: hospitalFilter,
          setHospital: setHospitalFilter,
          insuredName: insuredNameFilter,
          setInsuredName: setInsuredNameFilter,
          from: fromFilter,
          setFrom: setFromFilter,
          to: toFilter,
          setTo: setToFilter,
          getByClosedDate: getByClosedDate,
          setGetByClosedDate: setGetByClosedDate,
          getReinvestigation: getReinvestigation,
          setGetReinvestigation: setGetReinvestigation,
          getPushback: getPushback,
          setPushback: setPushback,
          onChange: onChange,
        },
      }}
    >
      <Button
        style={{
          float: 'right',
          marginTop: '20px',
          marginRight: '10px',
          backgroundColor: 'rgb(18, 33, 214)',
          color: 'white',
        }}
        animated
        onClick={async () => {
          localStorage.removeItem('caseFilters')
          setCaseCategoryFilter('')
          setCaseTypeFilter('')
          setClaimNumberFilter('')
          setClientFilter('')
          setStatesFilter('')
          setDistrictFilter('')
          setDoctorNameFilter('')
          setFromFilter('')
          setHospitalFilter('')
          setInsuredNameFilter('')
          setSearchFilter('')
          setToFilter('')
          setUserFilter('')
          setUserRoleFilter('')
          effectTriggerLoadCases()
          await effectLoadCases()
        }}
      >
        <Button.Content visible>Refresh</Button.Content>
        <Button.Content hidden>
          <Icon name="refresh" />
        </Button.Content>
      </Button>
      <Page title={pageTitle}>
        <button className="ui blue button" onClick={downloadData}>
          Export as Excel
        </button>
        <Segment>
          <Filters />
        </Segment>
        <div style={{ marginBottom: 24 }}>
          <Table
            renderer={caseColumnRenderer}
            data={cases}
            totalDataCount={totalCaseCount}
            loading={isLoading}
            onRowsPerPageChange={onRowsPerPageChange}
            load={() => setIsLoading(true)}
            onClear={() => setSearchFilter('')}
            onSearch={setSearchFilter}
          />
        </div>
      </Page>
    </CaseListPageContext.Provider>
  )

  async function effectLoadEssentialData() {
    if (!loadingEssentialData) return
    const p1 = roleRepo.getRoles({
      level_greater_than: user?.getRole().getLevel(),
    })
    const p2 = caseCategoryRepo.getCategories()
    const p3 = clientRepo.getClients()
    const p4 = stateRepo.getAll()
    // const p5 = hospitalRepo.getHospitals()
    const p6 = caseTypeRepo.getCaseTypes()
    const p7 = StateSDisterictRepo.getStates()
    const results = await Promise.all([p1, p2, p3, p4,
      //  p5, 
       p6, p7])

    if (results[0] instanceof Result.Success) {
      setRoleOptions(
        results[0].data.items.map((it) => ({
          text: it.getTitle(),
          value: it.getTitle(),
          key: it.getId(),
        }))
      )
    } else {
      const message = results[0].message || 'Could not load User Roles'
      toast.error(message)
    }

    if (results[1] instanceof Result.Success) {
      setCaseCategoryOptions(
        results[1].data.items.map((it) => ({
          text: it.getTitle(),
          value: it.getCode(),
          key: it.getCode(),
        }))
      )
    } else {
      const message = results[1].message || 'Could not load Case Categories'
      toast.error(message)
    }

    if (results[2] instanceof Result.Success) {
      setClientOptions(
        results[2].data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      )
    } else {
      const message = results[2].message || 'Could not load Clients'
      toast.error(message)
    }

    if (results[5] instanceof Result.Success) {
      setStateOptions(
        results[5].data.items.map((it) => ({
          text: it.getName(),
          value: it.getName(),
          key: it.getId(),
        }))
      );

    } else {
      const message = results[5].message || "Could not load State";
      toast.error(message);
    }

    if (results[3] instanceof Result.Success) {
      setDistrictOptions(
        results[3].data.items.map((it, i) => ({
          text: `${it.getDistrict()}, ${it.getState()}`,
          value: JSON.stringify({
            state: it.getState(),
            district: it.getDistrict(),
          }),
          key: i.toString(),
        }))
      )
    } else {
      const message = results[3].message || 'Could not load Districts'
      toast.error(message)
    }

    // if (results[4] instanceof Result.Success) {
    //   setHospitalOptions(
    //     results[4].data.items.map((it, i) => ({
    //       text: it.getName(),
    //       value: it.getId(),
    //       key: it.getId(),
    //     }))
    //   )
    // } else {
    //   const message = results[4].message || 'Could not load Hospitals'
    //   toast.error(message)
    // }

    if (results[4] instanceof Result.Success) {
      setCaseTypeOptions(
        results[4].data.items.map((it, i) => ({
          text: it.getTitle(),
          value: it.getId(),
          key: it.getId(),
        }))
      )
    } else {
      const message = results[4].message || 'Could not load caseTypes'
      toast.error(message)
    }

    setLoadingEssentialData(false)
  }

  function effectTriggerLoadCases() {
    setOffset(0)
    setIsLoading(true)
  }

  async function effectLoadCases() {
    if (!isLoading) return

    const result = await getCases()

    if (result instanceof Result.Success) {
      if (offset === 0) {
        await setCases(result.data.items)
        setTotalCaseCount(result.data.totalItemCount)
        setOffset(result.data.items.length)
      } else {
        const totalCases = [...cases, ...result.data.items]
        await setCases(totalCases)
        setOffset(totalCases.length)
      }
    } else {
      const message = result.message || 'Something went wrong'
      toast.error(message)
    }
    setIsLoading(false)
  }

 
  
  
  
  async function effectLoadUsers() {
    if (userRoleFilter.length === 0) return;
  
    let filter = { role: userRoleFilter, findTeam: false, is_active: true };
    if (userRoleFilter == constants.roleTitle.ca || userRoleFilter == constants.roleTitle.tl || userRoleFilter == constants.roleTitle.oh) {
      filter.findTeam = true;
    }    
    const result = await userRepo.getUsers(filter);
  
    console.log(result, "pppp");
    if (result instanceof Result.Success) {
      setUserOptions(
        result.data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = result.message || 'Could not load Users';
      toast.error(message);
    }
  }
  
  async function getCases() {
    const ro: GetCasesRequestObject = createRequestObject(); // createRequestObject should use outer userRoleFilter
    return await caseRepo.getCases(ro);
  }
  
  function createRequestObject() {
    // Remove the declaration of userRoleFilter here
    // const userRoleFilter: any = typeof userRoleFilter !== "undefined" ? userRoleFilter.trim() : "";
  
    const ro: GetCasesRequestObject = {
      limit,
      offset,
    };
  
    function isValidObjectId(id: any) {
      return typeof id === "string" && id.length === 24 && /^[a-fA-F0-9]+$/.test(id);
    }
  
    const validUserId = isValidObjectId(userFilter) ? userFilter : null;
    console.log(validUserId, "Valid User ID");
  
    const roleReporteeMap: Record<Role, string[]> = {
      admin: [],
      ceo: [],
      md: [],
      investigator: ["Client"],
      op: ["Client", "CaseCategory"], // For Operations
      tl: ["Designation"], // For Team Lead
      ClaimAnalyst: ["State/District", "CaseCategory", "Designation"],
      mi: [],
    };
  
    const roleMapping: Record<string, Role> = {
      Admin: "admin",
      "C.E.O.": "ceo",
      "M.D.": "md",
      Investigator: "investigator",
      Operations: "op",
      "Team Lead": "tl",
      "Claim Analyst": "ClaimAnalyst",
    };
  
    function getReportees(role: string): string[] {
      if (!role) return [];
      const normalizedRole = roleMapping[role as keyof typeof roleMapping];
      console.log("Normalized Role:", normalizedRole);
      return normalizedRole ? roleReporteeMap[normalizedRole] : [];
    }
  
    let reportees: string[] = [];
    if (userRoleFilter) {
      reportees = getReportees(userRoleFilter);
    }
  
    console.log("Final Reportees for Role:", reportees);
  
    if (validUserId || userRoleFilter) {
      ro.user = {
        id: validUserId,
        role: userRoleFilter || null,
        reportee: reportees,
      };
    }
  
    console.log("Valid User ID Before Assignment:", validUserId);
    console.log("Reportees:", reportees);
  
    if (caseCategoryFilter.trim().length !== 0) ro.case_category = caseCategoryFilter;
    if (caseTypeFilter.trim().length !== 0) ro.case_type_id = caseTypeFilter;
    if (caseStateFilter.trim().length !== 0) ro.state = caseStateFilter;
    if (clientFilter.trim().length !== 0) ro.client_id = clientFilter;
    if (statesFilter.trim().length !== 0) ro.location = { state: statesFilter };
    if (districtFilter.trim().length !== 0) {
      statesFilter
        ? (ro.location = { state: statesFilter, district: districtFilter })
        : (ro.location = JSON.parse(districtFilter));
    }
    if (claimNumberFilter.trim().length !== 0) ro.claim_number = claimNumberFilter;
    if (hospitalFilter.trim().length !== 0) ro.hospital_id = hospitalFilter;
    if (insuredNameFilter.trim().length !== 0) ro.insured_name = insuredNameFilter;
    if (doctorNameFilter.trim().length !== 0) ro.doctor_name = doctorNameFilter;
    if (fromFilter.trim().length !== 0) ro.from = fromFilter;
    if (toFilter.trim().length !== 0) ro.to = toFilter;
    if (searchFilter.trim().length !== 0) ro.search = searchFilter;
    if (getByClosedDate == true) ro.get_by_closed_date = true;
    if (getReinvestigation == true) ro.reinvestigation = true;
    if (getPushback == true) ro.pushback = true;
  
    return ro;
  }
  

  // District Function
  async function effectLoadDistrict() {
    if (statesFilter.length === 0) return;
    let filter = { state: statesFilter };

    const result = await StateSDisterictRepo.getDistricts(filter);
    if (result instanceof Result.Success) {
      setDistrictOptions(
        result.data.items.map((it, i) => ({
          text: it,
          value: it,
          key: i.toString(),
        }))
      );
    } else {
      const message = result.message || "Could not load District";
      toast.error(message);
    }
  }

  //  get Hospital
  async function effectLoadHospital(value: any) {
    // if (hospitalFilter.length === 0) return;
    let filter = { search: value };

    const result = await hospitalRepo.getHospitals(filter);
    if (result instanceof Result.Success) {
      setHospitalOptions(
        result.data.items.map((it, i) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = result.message || "Could not load hospital";
      toast.error(message);
    }
  }


  async function onChange(e: any) {

    const value = e.target.value;
    console.log(value, "vbaklduiri")
    if (value.length > 2) {
      setHospitalSearch(value);
      await effectLoadHospital(value);
    } else {
      setHospitalSearch("");
    }
  }
}
interface Filters {
  caseCategoryCode: string
  caseState: string
  userId: string
  roleTitle: string
  caseTypeId: string
  clientId: string
  states: string
  district: string
  search: string
  hospitalId: string
  claimNumber: string
  insuredName: string
  doctorName: string
  from: string
  to: string
  reinvestigation: boolean
  pushback: boolean
  reportee:any
}

export default (props: any) => {
  return (
    <RequirePermission
      permission={[
        permissions.Case.View.initiated,
        permissions.Case.View.generated,
        permissions.Case.View.open,
        permissions.Case.View.fold,
        permissions.Case.View.checked,
        permissions.Case.View.close,
      ]}
      any
    >
      <CaseListPage {...props} />
    </RequirePermission>
  )
}
